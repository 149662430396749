<template>
  <div>
    <ul class="brandstyle-logos">
      <li v-for="(item, i) in videoItems" :key="item.id">
        <div class="brandstyle-logos__item">
          <div
            class="brandstyle-logos__item-img"
            @click="lightboxIndex = i"><img
            :src="item.cover"
            :alt="item.title">
          </div>
          <div class="brandstyle-logos__item-text">
            {{item.title}} <br>
            <a class="button-rounded" :href="item.src"
               target="_blank" download="">
              <svg fill="none" viewBox="0 0 8 12">
                <path fill="#4F92FF" d="M3.528 7.056L.473 1.764h6.11L3.528 7.056z"
                      class="svg-triangle"/>
                <path stroke="#4F92FF" stroke-width="1.5" d="M0 11h7"/>
              </svg>
              <span>Скачать</span>
            </a>
          </div>
        </div>
      </li>
    </ul>
    <div class="brandstyle-logos__more g-side-line" v-if="showMore">
      <a href="#" @click.prevent="loadVideos">Показать ещё</a>
    </div>
    <CoolLightBox
      :items="videos"
      :index="lightboxIndex"
      :loop="false"
      :slideshow="false"
      :gallery="false"
      thumbs-position="bottom"
      @close="onLightboxClose"
      @on-open="onLightboxOpen"
    />
  </div>
</template>

<script>
  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import axios from 'axios'

  export default {
    name: "VideoList",
    components: {CoolLightBox},
    props: {
      videos: {
        type: Array,
        required : true,
      },
      count: {
        type: [String, Number],
        default: '6',
      },
      pages: {
        type: [String, Number],
        required: true,
      }
    },
    data() {
      return {
        lightboxIndex: null,
        htmlBlock: undefined,
        videoItems: [],
        nextPage: 2,
      }
    },
    computed: {
      showMore() {
        return this.nextPage <= this.pages;
      }
    },
    created() {
      this.videoItems = [...this.videos];
    },
    mounted() {
      this.htmlBlock = document.querySelector("html");
    },
    methods: {
      onLightboxOpen() {
        this.htmlBlock.style.overflow = "hidden";
      },
      onLightboxClose() {
        this.htmlBlock.style.overflow = "";
        this.lightboxIndex = null;
      },
      async loadVideos() {
        const res = await axios({
          method: 'get',
          url: '/local/ajax/videoPagination.php',
          params: {
            count: +this.count,
            page: this.nextPage++
          }
      });
        this.videoItems = this.videoItems.concat(res.data);
      }
    }
  }
</script>

<style scoped>
  .brandstyle-logos__item-img {
    cursor: pointer;
  }
</style>
