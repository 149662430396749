<template>
  <div class="header-search-toggle" @click="toggleSearch">
    <span class="header-search-toggle__text">Поиск</span>
    <inline-svg src="search"/>
  </div>
</template>

<script>
export default {
  name: "HeaderSearchToggle",
  props: {
    forMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSearch() {
      if (this.forMobile) {
        this.$root.$emit("toggleSearchHeaderMobile");
      } else {
        this.$root.$emit("toggleSearchHeader");
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".header-search-toggle";

#{$b} {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.25;
  color: inherit;

  @include tablet-only {
    width: 20px;
    height: 20px;
  }

  &__text {
    @include tablet-only {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .inline-svg {
    width: 17px;
    height: 17px;
  }
}
</style>
