<template>
  <div v-if="isActive" class="header-tab">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "HeaderTab",
    props: {
      title: { type: String, default: '' },
      selected: { type: Boolean, default: false },
      tabId: { type: String, required: true },
    },
    data() {
      return {
        isActive: false
      };
    },
    mounted() {
      this.isActive = this.selected;
    },
  };
</script>
