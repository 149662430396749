<template>
  <div class="lk-personal">
    <div class="lk-form__row">
      <div class="lk-form__col">
        <text-input
          name="fullName"
          label="фио"
          v-model="form.fullName"
          editableAction="#"
          @change="submit"
        />
      </div>
      <div class="lk-form__col">
        <text-input
          name="email"
          label="e-mail"
          v-model="form.email"
          is-read-only
        />
      </div>
    </div>
    <div class="lk-form__row">
    <div class="lk-form__col">
      <button @click.stop.prevent="openPassModal" class="button-rounded button-rounded--wider-pd button-rounded--blue lk-personal__btn">
        Сменить пароль
      </button>
    </div>
  </div>
  </div>

</template>

<script>
import axios from "axios";
import TextInput from "./TextInput";

export default {
  name: "LkPersonal",
  components: { TextInput },
  props: {
    fullName: { type: String },
    email: { type: String },
  },
  data() {
    return {
      form: {
        fullName: this.fullName,
        email: this.email,
      },
      selectedRegion: '0',
      OpenIndicator: {
        render: createElement => createElement('span')
      },
    }
  },
  methods: {
    async submit() {
      console.log('submit here!', this.form);

      const options = {
        url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:personal_form&action=update',
        method: 'POST',
        data: this.form
      };
      const response = await axios(options);

      if (response.data.status === 'error') {
        console.log('Errors:', response.data.errors);
        alert('Ошибка!')
      }
    },
    openPassModal() {
      this.$root.$refs.customModal.openModal(null, {
        name: 'change-pass',
        props: {},
        title: 'Смена пароля'
      });
    }
  }
}
</script>

<style lang="scss">
  @import "../scss/base/includes";
  $b: '.lk-personal';

  #{$b} {
    &__btn {
      margin-top: 50px;
    }
  }
</style>
