<template>
  <section class="hero-slider">
    <swiper ref="heroSwiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="hero-slider__controls-wrapper" v-rellax="{ speed: 2 }">
      <div class="g-container">
        <div class="hero-slider__controls">
          <div class="hero-slider__prev">
            <inline-svg src="arrow-left" />
          </div>
          <div class="hero-slider__count swiper-pagination"></div>
          <div class="hero-slider__next">
            <inline-svg src="arrow-right" />
          </div>
        </div>
        <div
          class="hero-slider__bullets g-pagination-bullets g-pagination-bullets--light"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSlider",
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 5000,
        },
        watchOverflow: true,
        navigation: {
          prevEl: ".hero-slider__prev",
          nextEl: ".hero-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".hero-slider__bullets",
          type: "bullets",
          bulletClass: "g-pagination-bullet",
          bulletActiveClass: "is-active",
          clickable: true
        },
        breakpoints: {
          1024: {
            pagination: {
              el: ".hero-slider__count",
              type: "fraction"
            }
          },
          1280: {
            allowTouchMove: false,
            pagination: {
              el: ".hero-slider__count",
              type: "fraction"
            }
          },
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.heroSwiper.$swiper;
    }
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".hero-slider";

#{$b} {
  background-size: cover;
  background-repeat: no-repeat;
  color: $color-black;

  &--light {
    color: $white-true;
    background-color: $color-dark-blue-2;
    background: linear-gradient(
      106.27deg,
      rgba(7, 41, 135, 0.7) 0%,
      rgba(7, 41, 135, 0) 100%
    ),
    #203b87;
  }

  &__inner {
    position: relative;
  }

  &__controls-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    bottom: 110px;

    @include desktop {
      bottom: 70px;
    }

    @include tablet {
      bottom: 80px;
    }

    @include mobile {
      bottom: 30px;
    }
  }

  &__controls {
    height: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;

    @include desktop {
      width: 88px;
    }

    @include tablet {
      display: none;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {
    display: none;

    @include tablet {
      display: flex;
      justify-content: flex-end;
      height: 0;
    }

    @include mobile {
      display: flex;
      justify-content: stretch;
      height: auto;
    }
  }

  &-item {
    position: relative;

    &.is-played {
      &::before {
        content: "";
        @include full-abs;
        z-index: -1;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(19,50,134,1) 100%);
        opacity: 0.5;
      }
    }

    &__inner {
      height: calc(778px - #{$offset-header});
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 0 80px;

      @include desktop {
        height: calc(600px - #{$offset-header});
        padding-bottom: 50px;
      }

      @include mobile {
        padding-bottom: 64px;
      }
    }

    &__text {
      max-width: 773px;

      @include desktop {
        max-width: 620px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include mobile {
          margin-bottom: 18px;
        }
      }
    }

    .g-h4 {
      position: relative;
      color: $color-base;
      margin: 0 0 20px;

      @include mobile {
        margin-bottom: 18px;
      }
    }

    &__title {
      display: block;
      font-weight: 700;
      font-size: 60px;
      line-height: 1;
      color: inherit;
      margin: 0 0 0 -5px;

      @include desktop {
        margin-left: -2px;
        font-size: 50px;
      }

      @include mobile {
        font-size: 28px;
        line-height: 1.2;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @include mobile {
          margin-bottom: 18px;
        }
      }
    }

    &__subtitle {
      max-width: 642px;
      display: block;
      font-size: 18px;
      line-height: 1.6;
      color: inherit;

      @include desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 1.6;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__image {
      max-width: 720px;
      position: absolute;
      bottom: 0;
      right: -60px;
      z-index: -1;

      @include desktop {
        right: -70px;
        max-width: 480px;
      }

      @include tablet {
        display: none;
      }

      #{$b}-item.is-played & {
        opacity: 0;
        visibility: hidden;
      }
    }

    &__bg-image {
      @include full-abs;
      @include bg-cover;
      z-index: -1;
      transition: .2s ease;
      transition-property: visibility, opacity;

      #{$b}-item.is-played & {
        opacity: 0;
        visibility: hidden;
      }

      &::after {
        content: "";
        @include full-abs;
        background-color: #041138;
        background: linear-gradient(to bottom, #041138, #000000);
        opacity: 0.5;
      }

      &>img {
        @include full-abs;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__video {
      @include full-abs;
      z-index: -2;
      object-fit: cover;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s ease;

      #{$b}-item.is-played & {
        opacity: 1;
        visibility: visible;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        justify-content: stretch;
      }

      & > * {
        &:not(:last-child) {
          @include tablet {
            margin-right: 30px;
          }
          @include mobile {
            margin-right: 18px;
          }
        }
      }
    }

    &__play-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      color: $color-base;

      #{$b}-item.is-played & {
        color: $white-true;
        background-color: transparent;
      }

      @include tablet {
        background-color: $color-base;
        color: $white-true;

        .inline-svg g:first-child path {
          fill: $color-base;
        }
      }

      @include mobile {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      .play-icon {
        #{$b}-item.is-played & {
          display: none;
        }
      }

      .pause-icon {
        display: none;

        #{$b}-item.is-played & {
          display: block;
        }
      }
    }
  }
}
</style>
