<template>
    <div class="text-input" :class="{_error: error}">
      <div v-if="label.length" class="text-input__label" v-html="label"></div>
      <div class="text-input__wrapper">
        <textarea
          v-if="isTextArea"
          :name="name"
          :value="value"
          :class="{_error: error}"
          :placeholder="placeholder"
          v-on:input="$emit('input', $event.target.value)"
        >
        </textarea>
        <input
          v-else
          :type="type"
          ref="input"
          :class="{_error: error, _editable: editableAction, _readonly: isReadOnly}"
          :name="name"
          :value="value"
          :placeholder="placeholder"
          :readonly="!!editableAction && !isEdit || isReadOnly"
          v-on:input="$emit('input', $event.target.value)"
          v-mask="mask ? mask : ''"
        >
        <div v-if="editableAction" class="text-input__edit">
          <div v-if="isEdit" class="text-input__edit-item _confirm" @click="submit">
            <span>сохр.</span>
            <inline-svg src="confirm-icon"/>
          </div>

          <div v-else class="text-input__edit-item _edit" @click="edit">
            <span>ред.</span>
            <inline-svg src="edit-icon"/>
          </div>
        </div>
        <div v-if="isPassword" class="text-input__eye" :class="{ 'is-active': passwordShowed }">
          <inline-svg v-if="passwordShowed" src="eye" @click.prevent="changePasswordType"/>
          <inline-svg v-else src="eye-close" @click.prevent="changePasswordType"/>
        </div>
      </div>
    </div>
</template>

<script>
  import InlineSvg from "./InlineSvg";
  import { VueMaskDirective } from 'v-mask'

  export default {
    name: "TextInput",
    components: {InlineSvg},
    props: {
      placeholder: String,
      value: String,
      name: String,
      label: {
        type: String,
        default: ''
      },
      error: {
        type: Boolean,
        default: false
      },
      isTextArea: {
        type: Boolean,
        default: false
      },
      editableAction: {
        type: String,
        required: false
      },
      mask: {
        type: String,
        required: false
      },
      inputType: {
        type: String,
        default: "text"
      },
      isPassword: {
        type: Boolean,
        default: false
      },
      isReadOnly: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        isEdit: false,
        passwordType: "password",
        passwordShowed: false,
        type: this.inputType,
      }
    },
    directives: {mask: VueMaskDirective},
    methods: {
      edit() {
        this.isEdit = true;
        this.$refs.input.focus();
      },
      changePasswordType() {
        this.passwordShowed = this.type !== "text";
        this.type = this.type === "text" ? "password" : "text";
      },
      submit() {
        this.$emit('change');
        console.log('submit to ' + this.editableAction);
        this.isEdit = false;
        this.$refs.input.blur();
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/base/includes";
  .text-input {
    color: $color-black;

    &.text-input--smaller {
      .text-input__label {
        margin-bottom: 7px;
      }

      input,
      textarea {
        font-size: 16px;
        padding-bottom: 15px;

        @include mobile {
          font-size: 14px;
          padding-bottom: 12px;
        }
      }
    }

    &__label {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 11px;

      @include mobile {
        font-size: 10px;
        margin-bottom: 6px;
      }

      .text-input--smaller & {
        margin-bottom: 7px;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__edit {
      cursor: pointer;
      user-select: none;
      position: absolute;
      top: 12px;
      right: 0;
      color: transparent;

      @include mobile {
        top: 0;
      }

      &-item {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        @include mobile {
          font-size: 10px;
        }

        &>span {
          color: $color-black;
          margin-right: 5px;

          @include mobile {
            margin-right: 3px;
          }
        }

        &._edit {
          &>span {
            color: rgba($color-black, 0.5);
          }

          .inline-svg {
            width: 20px;
            height: 20px;

            @include mobile {
              width: 18px;
              height: 18px;
            }
          }
        }

        &._confirm {
          &>span {
            color: $color-base;
          }

          .inline-svg {
            width: 24px;
            height: 16px;

            @include mobile {
              width: 20px;
              height: 14px;
            }
          }
        }
      }
    }

    &__eye {
      cursor: pointer;
      user-select: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #DEDEDE;

      &.is-active {
        color: $color-base;
      }

      .inline-svg {
        width: 23px;
        height: 12px;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 0 0 21px;
      border-bottom: $line-width solid rgba($color-black, .15);
      font-size: 22px;
      line-height: 160%;
      transition: .2s;

      @include mobile {
        font-size: 16px;

        .lk-form & {
          font-size: 14px;
          padding: 0 0 15px;
        }
      }

      &:focus {
        &:not(:read-only) {
          border-color: $color-base;
        }
      }

      &._error {
        border-color: $color-error;
      }

      &._readonly {
        color: #dedede;
      }
    }

    input {
      text-overflow: ellipsis;

      &:read-only {
        cursor: default;
      }

      &._editable {
        padding-right: 60px;

        @include mobile {
          padding-right: 50px;
        }
      }
    }

    textarea {
      min-height: 187px;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      resize: none;
      outline: none;
      background-color: transparent;

      @include mobile {
        font-size: 16px;
        min-height: 160px;
      }

      &:focus {
        border-color: $color-base;
      }

      &._error {
        border-color: $color-error;
      }
    }
  }
</style>
