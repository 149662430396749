<template>
  <form class="lk-plan">
    <div class="lk-plan__head">
      <div class="lk-form__subtitle-alt">
        Ваши предложенные мероприятия
      </div>
      <div v-if="reportTemplate" class="lk-plan__template">
        <a download :href="reportTemplate" class="lk-plan__template-download button-rounded button-rounded--wider-pd">
          <span></span>
          <span>
            СКАЧАТЬ шаблон отчёта.pptx
          </span>
        </a>
      </div>
    </div>
    <div class="lk-plan__container">
      <div class="lk-plan__table">
        <div class="lk-plan__table-row lk-plan__table-row--head">
          <div class="lk-plan__table-cell lk-plan__table-cell--num">
            №
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--title">
            Название
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--date">
            <button
              class="lk-plan__filter-btn"
              :class="{ _reverse: dateFromOlder }"
              @click.stop.prevent="dateFromOlder = !dateFromOlder"
            >
              Дата
            </button>
          </div>
        </div>
        <div
          v-for="(event, i) in sortedEvents"
          :key="event.id"
          class="lk-plan__table-row"
        >
          <div class="lk-plan__table-cell lk-plan__table-cell--num">
            {{ i + 1 }}.
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--title" v-html="event.title"/>
          <div class="lk-plan__table-cell lk-plan__table-cell--date">
            {{ formDate(event.date) }}
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--edit">
            <button @click.stop.prevent="getEvent(event.id)" class="lk-form__link">
              Редактировать
            </button>
          </div>
          <div class="lk-plan__table-cell lk-plan__table-cell--report">
            <div class="lk-plan__report" :class="event.report ? 'lk-plan__report--uploaded' : ''" :key="reportBtnKey">
              <template v-if="event.report">
                <a download :href="event.report" class="lk-form__link">
                  Посмотреть отчет
                </a>
                <button
                  class="lk-plan__remove-btn"
                  title="Удалить отчет"
                  @click.stop.prevent="removeReport(event.id)"
                />
              </template>
              <template v-else>
                <label class="lk-form__link lk-plan__report-label">
                  <input type="file" @change="uploadReport($event, event.id)" accept="application/vnd.openxmlformats-officedocument.presentationml.presentation">
                  Загрузить отчёт
                </label>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="lk-plan__info">
        *Срок загрузки отчета - не позднее 5 дней с момента завершения мероприятия
      </div>
      <div class="lk-plan__action">
        <button @click.stop.prevent="$root.$emit('header-tabs-change', 'add-event')" type="button" class="lk-plan__button button button--base">
          ЗАПОЛНИТЬ ЕЩЕ ЗАЯВКУ
        </button>
      </div>
    </div>
  </form>
</template>

<script>
  import axios from 'axios';

  export default {
    name: "LkPlan",
    props: {
      reportTemplate: { type: String, default: '' },
      events: { type: Array, required: true },
    },
    data() {
      return {
        months: {
          '01': 'января',
          '02': 'февраля',
          '03': 'марта',
          '04': 'апреля',
          '05': 'мая',
          '06': 'июня',
          '07': 'июля',
          '08': 'августа',
          '09': 'сентября',
          '10': 'октября',
          '11': 'ноября',
          '12': 'декабря',
        },
        dateFromOlder: false,
        currentEditId: null,
        reportBtnKey: 0,
      };
    },
    computed: {
      sortedEvents() {
        const arrToSort = [...this.events];
        return arrToSort.sort(this.sortDates);
      }
    },
    methods: {
      getEvent(id) {
        this.currentEditId = id;
        this.$root.$refs.customModal.openModal(null, null, true);

        axios.get(`/local/ajax/getEventInfo.php?id=${id}`)
          .then((res) => {
            if (res.data.status === 'success') {
              this.$root.$refs.customModal.passContent({
                name: 'event-form',
                props: this.formEventFormProps(res.data.data),
                title: 'Редактировать региональный план'
              });
            } else {
              this.$root.$refs.customModal.closeModal();
              this.$root.$refs.eventErrorModal.openModal();
            }
          })
          .catch((e) => {
            console.error(e);
            this.$root.$refs.customModal.closeModal();
            this.$root.$refs.eventErrorModal.openModal();
          });
      },
      formEventFormProps(data) {
        const submitUrl = '/local/ajax/updateEvent.php';
        const requestId = this.currentEditId;
        const {
          'month-themes-options': monthThemesOptions,
          'regions-options': regionsOptions,
          files: initialFiles,
          ...initialData
        } = data;
        return { monthThemesOptions, regionsOptions, initialFiles, initialData, submitUrl, requestId };
      },
      removeReport(id) {
        axios.post('/local/ajax/updateEvent.php', {
          removeReport: id,
        })
          .then((res) => {
            if (res.data.status === 'success') {
              this.handleReport(id);
            } else {
              console.log(`Request failed, status ${res.data.status}`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      uploadReport($event, id) {
        const data = new FormData();
        data.append('id', id);
        data.append('report', $event.target.files[0]);

        axios({
          url: '/local/ajax/updateEvent.php',
          method: 'post',
          headers: {'content-type': 'multipart/form-data'},
          data,
        })
          .then((res) => {
            if (res.data.status === 'success') {
              this.handleReport(id, res.data.url);
            } else {
              console.log(`Request failed, status ${res.data.status}`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      handleReport(id, url = null) {
        this.events.forEach((event) => {
          if (event.id === id) {
            event.report = url;
            this.reportBtnKey += 1;
          }
        });
      },
      getDate(str) {
        const parts = str.split('.');
        const newStr = `${parts[1]}/${parts[0]}/${parts[2]}`;

        return new Date(newStr);
      },
      rmZero(str) {
        return str[0] === '0' ? str.substring(1) : str;
      },
      formDate(dateArr) {
        if (dateArr.length === 1) {
          const parts = dateArr[0].split('.');
          return `${this.rmZero(parts[0])} ${this.months[parts[1]]}`;
        } else {
          const parts1 = dateArr[0].split('.');
          const parts2 = dateArr[1].split('.');
          return `${this.rmZero(parts1[0])} ${parts1[1] === parts2[1] ? '—' : `${this.months[parts1[1]]} —`} ${this.rmZero(parts2[0])} ${this.months[parts2[1]]}`;
        }
      },
      sortDates(event1, event2) {
        const date1 = this.getDate(event1.date[0]);
        const date2 = this.getDate(event2.date[0]);
        return this.dateFromOlder ? (date2 - date1) : (date1 - date2);
      },
    }
  };
</script>

<style lang="scss">
  @import "../scss/base/includes";

  $b: '.lk-plan';

  $pd-right: 18px;
  $cell-num: 42px;
  $cell-report: 180px;
  $cell-edit: 150px;
  $cell-date: 210px;
  $cell-date-header: #{$cell-report + $cell-edit + $cell-date};

  #{$b} {
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__template {
      padding-top: 9px;

      &-download {
        display: flex;

        span {
          &:first-child {
            margin-right: 9px;
            position: relative;
            width: 6px;
            max-width: 6px;
            height: 10px;

            &::before,
            &::after {
              content: "";
              position: absolute;
            }

            &::before {
              border-top: 5px solid $color-base;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              top: 0;
              left: 0;
            }

            &::after {
              top: 8px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: $color-base;
            }
          }

        }
      }
    }

    &__container {
      padding-top: 75px;
    }

    &__table {
      font-size: 16px;

      &-row {
        display: flex;

        @include tablet {
          flex-wrap: wrap;
          padding-top: 30px;
        }

        &--head {
          font-weight: 500;
          font-size: 12px;
          color: rgba($color-black, .5);
          border-bottom: 2px solid rgba($color-black, .12);
          padding-top: 0;

          #{$b}__table-cell {
            padding-bottom: 18px;

            &--title {
              @include mobile {
                display: none;
              }
            }

            &--date {
              flex: $cell-date-header 0 1;
              max-width: $cell-date-header;

              @include tablet {
                flex: $cell-date 0 1;
                max-width: $cell-date;
              }

              @include mobile {
                flex: calc(100% - #{$cell-num}) 1 1;
                padding-right: 0;
                margin-left: 0;
              }
            }
          }
        }
      }

      &-cell {
        padding-top: 20px;

        @include tablet {
          padding-top: 0;
        }

        &--num {
          flex: $cell-num 0 0;
          max-width: $cell-num;
        }

        &--title {
          flex: 1 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 55px;

          @include tablet {
            flex: calc(100% - #{$cell-num + $cell-date}) 1 1;
            padding-right: 30px;
          }

          @include mobile {
            flex: calc(100% - #{$cell-num}) 1 1;
            padding-right: 0;
          }
        }

        &--date {
          flex: $cell-date 0 0;
          max-width: $cell-date;
          padding-right: $pd-right;

          @include mobile {
            flex: 100% 1 1;
            margin-left: $cell-num;
            padding-right: 0;
          }
        }

        &--edit,
        &--report {
          text-align: right;
          padding-right: $pd-right;

          @include tablet {
            text-align: left;
          }
        }

        &--edit {
          flex: $cell-edit 0 0;
          max-width: $cell-edit;

          @include tablet {
            margin-left: $cell-num;
          }

          @include mobile {
            flex: 100% 1 1;
            margin-left: $cell-num;
            padding-right: 0;
            max-width: 100%;
          }
        }

        &--report {
          flex: $cell-report 0 0;
          max-width: $cell-report;

          @include tablet {
            flex-grow: 1;
            max-width: unset;
          }

          @include mobile {
            flex: 100% 1 1;
            margin-left: $cell-num;
            padding-right: 0;
            max-width: 100%;
          }
        }
      }
    }

    &__filter-btn {
      border: 0;
      background: transparent;
      color: inherit;
      font-weight: inherit;
      padding-left: 15px;
      position: relative;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
      }

      &::before {
        border-bottom: 5px solid $color-base;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        top: 2px;
        left: 0;
      }

      &::after {
        border-top: 5px solid $color-base;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        top: 11px;
        left: 0;
        opacity: .5;
      }

      &._reverse {
        &::before {
          opacity: .5;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &__report {
      &--uploaded {
        position: relative;
        padding-right: 20px;
        margin-right: -20px;

        @include tablet {
          margin-right: unset;
          width: max-content;
          max-width: 170px;
        }
      }

      &-label {
        input {
          position: absolute;
          width: 0;
          height: 0;
          appearance: none;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &__remove-btn {
      @include remove-cross;
      position: absolute;
      top: 9px;
      right: 0;
      width: 20px;
      max-width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &__info {
      font-weight: 500;
      font-size: 12px;
      color: rgba($color-black, .5);
      padding: 40px 0;
    }
  }
</style>
