<template>
  <div :class="{'_opened': isOpened}">
    <h4
      @click.stop="accordionAction"
      class="accordion__header"
    >
      <slot name="header" />
    </h4>
    <VueSlideToggle
      :open="isOpened"
      tag="div"
      :duration="300"
    >
      <div class="accordion__body">
        <div class="accordion__content-container">
          <slot name="body" />
        </div>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';

export default {
  name: 'Accordion',
  components: {
    VueSlideToggle,
  },
  props: {
    opened: { type: Boolean, default: false },
  },
  data() {
    return {
      isOpened: this.opened,
    };
  },
  methods: {
    accordionAction() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>

