<template>
  <div class="photobank">
    <div class="g-wrapper-offset-left">
      <photobank-filter
        :initial-filters="initParams.initialFilters"
        :category-options="initParams.categoryOptions"
        :sorting-options="initParams.sortingOptions"
        :initial-month-filters="initParams.initialMonthFilters"
        @change="onFilterChange"
      />
      <file-uploader
        v-if="isUploaderShowed"
        :category-options="initParams.categoryOptions"
        :month-options="initParams.initialMonthFilters"
        @complete="loadFirstPage"
      />
    </div>
    <div class="photobank__body">
      <div class="g-wrapper-offset-left">
        <div class="photobank-switch g-hidden-tablet">
          <div
            class="photobank-switch__item"
            :class="{ 'is-active': grid === 3 }"
            @click.prevent="toggleGrid(3)"
          >
            <div class="photobank-switch__icon">
              <inline-svg src="layout-three" />
            </div>
          </div>
          <div
            class="photobank-switch__item"
            :class="{ 'is-active': grid === 4 }"
            @click.prevent="toggleGrid(4)"
          >
            <div class="photobank-switch__icon">
              <inline-svg src="layout-four" />
            </div>
          </div>
        </div>
        <ul
          v-if="items.length"
          class="photobank-list"
          :class="{
            'photobank-list--grid-1-3': grid === 3,
            'photobank-list--grid-1-4': grid === 4
          }"
        >
          <li v-for="(item, index) in items" :key="index">
            <div
              :ref="'item-' + index"
              class="photobank-item"
              :class="{ 'is-video': item.isVideo }"
            >
              <div class="photobank-item__image">
                <template v-if="item.isVideo">
                  <div
                    class="photobank-item__play"
                    @click.prevent="lightBoxIndex = index"
                  >
                    <inline-svg src="play-btn-2"></inline-svg>
                  </div>
                  <object-fit-video
                    preload="metadata"
                    :poster="item.file.poster"
                  >
                    <source :src="item.file.src" />
                  </object-fit-video>
                </template>
                <template v-else>
                  <div class="photobank-item__image-overlay">
                    <ul class="photobank-item__image-actions">
                      <li>
                        <a
                          class="photobank-item__image-icon"
                          :href="item.file.srcLarge"
                          title="Увеличить"
                          @click.prevent="lightBoxIndex = index"
                        >
                          <inline-svg src="loupe-plus" />
                        </a>
                      </li>
                      <li>
                        <a
                          class="photobank-item__image-icon"
                          :href="item.file.srcLarge"
                          download
                          title="Скачать"
                        >
                          <inline-svg src="download-circle" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <img :src="item.file.src" :alt="item.file.alt" />
                </template>
              </div>
              <div class="photobank-item__title" v-html="item.title"></div>
              <div class="photobank-item__format">
                {{ getFileFormat(item.file.src) }}
              </div>
            </div>
          </li>
        </ul>
        <not-found v-else text="По данным параметрам ничего не найдено" />
        <div
          class="photobank__load-more"
          v-show="paginator.NavPageNomer < paginator.NavPageCount"
        >
          <div class="g-h4" @click="loadMoreItems(false)">Показать ещё</div>
        </div>
      </div>
    </div>

    <CoolLightBox
      :items="lightBoxItems"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Photobank",
  props: {
    csrf: {
      type: String,
      required: true
    },
    url: {
      type: String
      // required: true
    },
    initParams: {
      type: Object,
      default: () => {
        return {
          initialFilters: [
            { name: "Всё", filter: "all", isActive: true },
            { name: "Фото", filter: "photo", isActive: false },
            { name: "Видео", filter: "video", isActive: false }
          ],
          categoryOptions: [
            { id: "", name: "Все категории" },
            { id: "1", name: "Категория 1" }
          ],
          sortingOptions: [
            { id: "", name: "Сначала новые" },
            { id: "1", name: "Сначала старые" }
          ]
        };
      }
    },
    initItems: {
      type: Array,
      default: () => [
        {
          file: {
            src: "/frontend/assets/img/photobank/content-01.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-01.jpg",
            alt: "Картинка"
          },
          title: "Название №1",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-02.png",
            srcLarge: "/frontend/assets/img/photobank/content-02.png",
            alt: "Картинка"
          },
          title: "Название №2",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-03.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-03.jpg",
            alt: "Картинка"
          },
          title: "Очень длинное название фотографии под названием №3",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-04.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-04.jpg",
            alt: "Картинка"
          },
          title: "Название №4",
          isVideo: false
        },
        {
          file: {
            src: "http://techslides.com/demos/sample-videos/small.mp4",
            poster: "/frontend/assets/img/photobank/content-05.png"
          },
          title: "Название №5",
          isVideo: true
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-06.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-06.jpg",
            alt: "Картинка"
          },
          title: "Название №6",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-07.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-07.jpg",
            alt: "Картинка"
          },
          title: "Название №7",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-08.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-08.jpg",
            alt: "Картинка"
          },
          title: "Название №8",
          isVideo: false
        },
        {
          file: {
            src: "http://techslides.com/demos/sample-videos/small.mp4",
            poster: "/frontend/assets/img/photobank/content-09.jpg"
          },
          title: "Название №9",
          isVideo: true
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-10.png",
            srcLarge: "/frontend/assets/img/photobank/content-10.png",
            alt: "Картинка"
          },
          title: "Название №10",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-11.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-11.jpg",
            alt: "Картинка"
          },
          title: "Название №11",
          isVideo: false
        },
        {
          file: {
            src: "/frontend/assets/img/photobank/content-12.jpg",
            srcLarge: "/frontend/assets/img/photobank/content-12.jpg",
            alt: "Картинка"
          },
          title: "Название №12",
          isVideo: false
        }
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {
        return {
          NavNum: 1,
          NavPageCount: 24,
          NavPageNomer: 1,
          NavPageSize: 12,
          NavRecordCount: 100
        };
      }
    },
    isUploaderShowed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterState: {},
      items: this.initItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
      lightBoxIndex: null,
      grid: 3
    };
  },
  computed: {
    lightBoxItems() {
      return this.items.map(item => {
        return item.isVideo ? item.file.src : item.file.srcLarge;
      });
    },
    filterStateMediaType() {
      const res = this.initParams.initialFilters.filter(el => el.isActive)[0];
      if (typeof res.id !== 'undefined') {
        return res.id;
      }
      return false;
    }
  },
  methods: {
    getFileFormat(file) {
      return file.slice((Math.max(0, file.lastIndexOf(".")) || Infinity) + 1);
    },
    toggleGrid(number) {
      this.grid = number;
    },
    onFilterChange(filterState) {
      this.filterState = filterState;
      this.loadFirstPage(true);
    },
    loadFirstPage() {
      const lines = [];
      if (this.filterState.filter) {
        lines.push(`filter=${this.filterState.filter}`);
      }
      if (this.filterStateMediaType) {
        lines.push(`mediaType=${this.filterStateMediaType}`);
      }
      if (this.filterState.category) {
        lines.push(`category=${this.filterState.category}`);
      }
      if (this.filterState.sorting) {
        lines.push(`sorting=${this.filterState.sorting}`);
      }
      if (this.filterState.month.length) {
        lines.push(this.filterState.month.map(id => `months[]=${id}`).join('&'));
      }
      const params = lines.join("&");

      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.items = [];
      }
      res.data.ITEMS.map(item => {
        this.items.push(item);
      });
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".photobank";
$offsetTop: 64px;

#{$b} {
  &-section {
    position: relative;
    z-index: 2;
    padding-top: 0;
    padding-bottom: 70px;
    margin-top: #{-$offsetTop};

    @include desktop {
      padding-bottom: 50px;
    }

    .figures {
      position: absolute;
      top: $offsetTop;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__body {
    position: relative;

    &:not(:first-child) {
      margin-top: 70px;

      @include desktop {
        margin-top: 50px;
      }

      @include tablet {
        margin-top: 30px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 36px;

      @include desktop {
        margin-bottom: 24px;
      }

      @include tablet {
        margin-bottom: 14px;
      }
    }
  }

  &-switch {
    position: absolute;
    top: 0;
    left: 0;

    &__item {
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      color: #bababa;
      border: 1.5px solid currentColor;
      transition: color .2s ease;

      #{$b}-switch__item:not(.is-active):hover & {
        color: #676767;
      }

      #{$b}-switch__item.is-active & {
        color: $color-base;
      }

      .inline-svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -40px -40px;

    @include low-desktop {
      margin: 0 0 -30px -30px;
    }

    & > li {
      width: 100%;
      padding-left: 40px;
      margin-bottom: 40px;
      transition: width .2s ease;

      @include low-desktop {
        width: 100% / 2 * 1;
        padding-left: 30px;
        margin-bottom: 30px;
      }

      @include mobile {
        width: 100%;
      }
    }

    &--grid-1-3 {
      & > li {
        @include low-desktop-min {
          width: 100% / 3 * 1;
        }
      }
    }

    &--grid-1-4 {
      & > li {
        @include tablet-min {
          width: 100% / 3 * 1;
        }
        @include low-desktop-min {
          width: 100% / 4 * 1;
        }
      }
    }
  }

  &-item {
    display: block;
    text-decoration: none;
    color: $color-black;

    &.is-played {
      cursor: pointer;
    }

    &__image {
      display: block;
      text-decoration: none;
      color: $color-black;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 56%;
      }

      &:hover {
        #{$b}-item__image-overlay {
          opacity: 1;
          visibility: visible;
        }
      }

      & > img,
      & > video {
        @include full-abs;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-overlay {
        @include full-abs;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease;
        transition-property: opacity, visibility;

        &::before {
          content: "";
          @include full-abs;
          z-index: -1;
          background: linear-gradient(
              119.62deg,
              rgba(7, 41, 135, 0.7) 0%,
              rgba(7, 41, 135, 0) 82.36%
            ),
            #203b87;
          opacity: 0.6;
        }
      }

      &-actions {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        & > li {
          &:not(:first-child) {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 2px solid $white-true;
          }
        }
      }

      &-icon {
        display: block;
        text-decoration: none;
        color: $white-true;
        transition: color 0.2s ease;

        &:hover {
          color: $color-base;
        }

        .inline-svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      cursor: pointer;
      border-radius: 60px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white-true;
      color: $color-base;
      transition: 0.2s ease;
      transition-property: opacity, visibility;

      @include mobile {
        border-radius: 30px;
        width: 30px;
        height: 30px;
      }

      .is-played & {
        opacity: 0;
        visibility: hidden;
      }

      .inline-svg {
        width: 15px;
        height: 15px;
        margin-right: -2px;

        @include mobile {
          width: 9px;
          height: 9px;
        }
      }
    }

    &__title {
      display: block;
      font-size: 14px;
      line-height: 20px;
    }

    &__format {
      font-size: 12px;
      line-height: 20px;
      opacity: 0.5;
    }
  }

  &__load-more {
    cursor: pointer;
    position: relative;
    color: $color-base;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-black;
    }

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:after {
      content: "";
      width: 116px;
      height: 1.5px;
      position: absolute;
      top: 0.5em;
      right: calc(100% + 38px);
      background-color: $color-blue-line;
      background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

      @include desktop {
        right: calc(100% + 30px);
        width: 85px;
      }

      @include tablet {
        position: static;
        margin-left: 25px;
      }

      @include mobile {
        width: 55px;
      }
    }

    &:not(:first-child) {
      margin-top: 70px;

      @include tablet {
        margin-top: 54px;
      }

      @include mobile {
        margin-top: 36px;
      }
    }
  }

  .cool-lightbox {
    &.cool-lightbox--can-zoom {
      .cool-lightbox__slide {

        img {
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
