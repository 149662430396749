<template>
  <div v-if="isShow" class="header-search" :class="{ 'is-showed': isShow }">
    <form ref="searchForm" class="header-search__form" action="/search/" @submit="onSubmit">
      <input
        class="header-search__input"
        type="text"
        placeholder="Поиск"
        name="q"
        v-model="search"
      />
      <div class="header-search__close" @click="closeSearch">
        <inline-svg src="close-thin" />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    forMobile: {
      type: Boolean,
      default: false
    }
  },
  name: "HeaderSearch",
  data() {
    return {
      isShow: false,
      search: "",
      header: undefined,
    };
  },
  mounted() {
    if (this.forMobile) {
      this.$root.$on("toggleSearchHeaderMobile", this.toggleSearch);
    } else {
      this.$root.$on("toggleSearchHeader", this.toggleSearch);
      this.header = document.querySelector("#header");
    }
  },
  methods: {
    toggleSearch() {
      this.isShow = !this.isShow;

      if (this.isShow && !this.forMobile) {
        this.header.classList.add("is-header-search-opened");
      } else {
        this.header.classList.remove("is-header-search-opened");
      }
    },
    closeSearch() {
      this.isShow = false;
      this.header.classList.remove("is-header-search-opened");
    },
    onSubmit() {
      const url = "/search/?q=" + this.search;
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".header-search";

#{$b} {
  position: absolute;
  top: 18px;
  right: 0;
  bottom: auto;
  left: 0;

  @include mobile {
    top: -6px;

    ~ div {
      display: none;
    }
  }

  &__form {
    display: block;
    position: relative;
    color: $white-true;
  }

  &__input {
    height: 60px;
    width: 100%;
    display: block;
    padding: 0 20px 20px 0;
    border: 0;
    border-bottom: 6px solid $color-base;
    color: inherit;
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    appearance: none;
    transition: 0.2s ease;
    transition-property: color, border-color;

    &:focus {
      border-bottom-color: rgba($color-base, 0.7);
    }

    @include mobile {
      height: 38px;
      font-size: 18px;
      line-height: 35px;
      padding: 0 20px 8px 0;
      border-width: 3px;
    }

    @include placeholder {
      color: inherit;
      opacity: 0.8;
    }
  }

  &__close {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 60px - 6px;
    text-align: right;
    color: inherit;
    padding: 13px 0 0;
    transition: color 0.2s ease;

    &:hover,
    &:focus {
      color: $color-base;
    }

    @include mobile {
      height: 50px - 6px;
      padding-top: 5px;
    }

    .inline-svg {
      display: inline-block;
      width: 17px;
      height: 17px;
    }
  }
}
</style>
