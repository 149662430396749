<template>
  <div
    class="burger-menu"
    :class="{ 'burger-menu--showed': isShowed }"
    v-body-scroll-lock:reserveScrollBarGap="isShowed"
  >
    <transition name="fade">
      <div v-show="isShowed" class="burger-menu__wrapper">
        <figures
          :items="[
            {'name':'figure1','image':'/frontend/assets/img/figure-01.png','animation':'fade-in-right'},
            {'name':'figure2','image':'/frontend/assets/img/figure-02.png','animation':'fade-in-left'}
          ]"
          is-overflow
        >
          <div class="burger-menu__wrapper-outter g-offset-header">
            <div class="g-container">
              <div class="burger-menu__wrapper-inner g-wrapper-offset-left">
                <div class="burger-menu__grid">
                  <div class="g-visible-mobile">
                    <slot name="user-menu"/>
                  </div>
                  <div class="burger-menu__grid-left">
                    <slot name="main-menu"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figures>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
  data() {
    return {
      isShowed: false,
      htmlBody: undefined
    };
  },
  mounted() {
    this.$root.$on("burgerMenuToggle", this.toggleMenu);
    this.htmlBody = document.querySelector("html");
  },
  methods: {
    toggleMenu(state) {
      this.isShowed = state;
      this.htmlScrollLock(this.isShowed);
    },
    // IOS fix
    htmlScrollLock(state) {
      if (state) {
        setTimeout(() => {
          this.htmlBody.style.overflow = "hidden";
        }, 50);
      } else {
        this.htmlBody.style.overflow = "";
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".burger-menu";

#{$b} {
  .figures {
    background: linear-gradient(180deg, rgba(29, 56, 135, 0) 0%, rgba(#102250, 0.5) 100%);
    min-height: 100%;
  }

  &__wrapper {
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    color: $white-true;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: transparent;
    transition: background-color .5s ease;
    background-color: $color-dark-blue-2;
    background: linear-gradient(
      106.27deg,
      rgba(7, 41, 135, 0.7) 0%,
      rgba(7, 41, 135, 0) 100%
    ),
    #203b87;

    @include low-desktop {
      padding-right: 0;
    }

    &-inner {
      display: block;
      padding-top: 125px;
      padding-bottom: 50px;

      @include desktop {
        padding-top: 63px;
      }

      @include tablet-only {
        padding-top: 73px;
      }
    }
  }

  &__grid {
    display: flex;

    @include tablet {
      flex-direction: column;
    }

    &-left {
      width: 100% / 3 * 2;
      padding-top: 20px;
      padding-right: 40px;

      @include desktop {
        padding-top: 10px;
      }

      @include tablet {
        width: 100%;
        padding: 0 0 60px;
      }

      @include mobile {
        padding-bottom: 35px;
      }
    }

    &-right {
      width: 100% / 3 * 1;
      padding-top: 20px;
      padding-left: 40px;
      border-left: 1px solid $color-base;

      @include desktop {
        padding-top: 10px;
      }

      @include tablet {
        width: 100%;
        padding: 70px 0 0;
        border-left: 0;
        border-top: 1px solid $color-base;
      }

      @include mobile {
        padding-top: 35px;
      }
    }
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;

    &--main {
      column-count: 2;
      column-gap: 30px;

      @include mobile {
        column-count: 1;
        column-gap: 0px;
      }

      &>li {

        &>a {
          position: relative;

          &::after {
            content: "";
            width: 72px;
            height: 2px;
            position: absolute;
            top: 19px;
            right: calc(100% + 27px);
            background-color: transparent;
            transition: background-color .3s ease;

            @include desktop {
              top: 14px;
            }

            @include low-desktop {
              display: none;
            }
          }

          &:hover,
          &:focus {
            &::after {
              background-color: $color-base;
            }
          }
        }
      }
    }

    &>li {
      &:not(:last-child) {
        margin-bottom: 35px;

        @include mobile {
          margin-bottom: 20px;
        }
      }

      &>a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        font-weight: 400;
        font-size: 28px;
        line-height: 1.4;
        transition: color .3s ease;

        @include desktop {
          font-size: 22px;
        }

        @include mobile {
          font-size: 16px;
        }

        &:hover,
        &:focus {
          color: $color-base;
          text-decoration: none;
        }
      }
    }
  }

  &__top {
    position: relative;
    top: -20px;
    display: flex;
    justify-content: space-between;
    min-height: 21px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.2s;
    transition-property: opacity;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
