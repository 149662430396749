<template>
  <div class="change-pass">
    <form v-if="!showResult" class="change-pass__form" @submit.prevent="onSubmit">
      <div class="change-pass__field">
        <text-input
          class="text-input--smaller"
          name="password"
          label="Старый пароль"
          v-model="form.password"
          input-type="password"
          :error="$v.form.password.$error || errors.password"
          @input="errors.password = false"
          isPassword
        />
        <div class="change-pass__error" v-if="$v.form.password.$error && !$v.form.password.required">Обязательное поле</div>
        <div v-if="errors.password" class="change-pass__error change-pass__pass-err">
          Введен неверный пароль. Попробуйте еще раз.
        </div>
      </div>
      <div class="change-pass__field">
        <text-input
          class="text-input--smaller"
          name="password_new"
          label="Новый пароль"
          v-model="form.password_new"
          input-type="password"
          :error="$v.form.password_new.$error"
          isPassword
        />
        <div class="change-pass__error" v-if="$v.form.password_new.$error && !$v.form.password_new.required">Обязательное поле</div>
        <div class="change-pass__error" v-if="$v.form.password_new.$error && !$v.form.password_new.minLength">Должен содержать не менее 8 символов</div>
      </div>
      <div class="change-pass__field">
        <text-input
          class="text-input--smaller"
          name="password_new2"
          label="Новый пароль еще раз"
          v-model="form.password_new2"
          input-type="password"
          :error="$v.form.password_new2.$error"
          isPassword
        />
        <div class="change-pass__error" v-if="$v.form.password_new2.$error && !$v.form.password_new2.required">Обязательное поле</div>
        <div class="change-pass__error" v-if="$v.form.password_new2.$error && !$v.form.password_new2.sameAs && $v.form.password_new2.$model.length">Пароли не совпадают</div>
      </div>
      <div class="change-pass__field">
        <button
          class="change-pass__button button button--base"
          type="submit"
        >
          Сменить пароль
        </button>
      </div>
    </form>
    <div v-else class="change-pass__result">
      <div class="change-pass__field">
        {{ errors.request ? 'Что-то пошло не так. Проверьте своё подключение к Сети или попробуйте позже.' : 'Пароль был успешно изменён.'}}
      </div>
      <div class="change-pass__field">
        <button
          class="change-pass__button button button--base"
          type="button"
          @click.stop.prevent="$root.$emit('modal-cancel')"
        >
          Хорошо
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { validationMixin } from 'vuelidate';
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';

  export default {
    name: "ChangePass",
    mixins: [validationMixin],
    data() {
      return {
        form: {
          password: '',
          password_new: '',
          password_new2: '',
        },
        errors: {
          request: false,
          password: false,
        },
        showResult: false,
      };
    },
    validations: {
      form: {
        password: { required },
        password_new: { required, minLength: minLength(8) },
        password_new2: { required, sameAs: sameAs('password_new') },
      }
    },
    methods: {
      async onSubmit() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          axios.post('/bitrix/services/main/ajax.php?mode=class&c=dalee:personal_form&action=updatePassword', {
            password: this.form.password,
            password_new: this.form.password_new
          })
          .then((res) => {
            this.handleResponse(res.data);
          })
          .catch((e) => {
            console.error(e);
            this.handleResponse();
          });
        }
      },
      handleResponse(data) {
        if (data.status === 'success') {
          this.showResult = true;
        } else if (data.errors[0].message === 'wrong-password') {
          this.errors.password = true;
        } else {
          this.showResult = true;
          this.errors.request = true;
        }
      }
    },
  };
</script>

<style lang="scss">
  @import "../scss/base/includes";

  $b: ".change-pass";

  #{$b} {
    &__field {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 35px;
      }

      &:last-child {
        padding-top: 5px;
      }
    }

    &__error {
      position: absolute;
      left: 0;
      top: calc(100% + 3px);
      font-size: 12px;
      line-height: 160%;
      color: $color-error;
    }

    &__button {
      width: 100%;
    }
  }
</style>
