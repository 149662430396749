import "./scss/app.scss";
import "swiper/css/swiper.css";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import Vue from "vue";
import registerVueDirectives from "./directives";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueDragscroll from "vue-dragscroll";
import VueRellax from "vue-rellax";
import VueSmoothScroll from "vue2-smooth-scroll";
import VBodyScrollLock from "v-body-scroll-lock";
import VueObjectFit from "vue-object-fit";
import CoolLightBox from "vue-cool-lightbox";
import vClickOutside from "v-click-outside";

registerVueDirectives();

Vue.use(VueAwesomeSwiper);
Vue.use(VueDragscroll);
Vue.use(VueRellax);
Vue.use(VueSmoothScroll);
Vue.use(VBodyScrollLock);
Vue.use(VueObjectFit);
Vue.use(CoolLightBox);
Vue.use(vClickOutside);

const files = require.context("./components", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

const moment = require("moment");
require("moment/locale/ru");

Vue.use(require("vue-moment"), {
  moment
});

Vue.filter("formatNumber", function (val) {
  if (!val) {
    return 0;
  }
  val = parseFloat(parseFloat(val).toFixed(2));
  return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(/[.]/g, ',');
});

Vue.filter("formatNumberLimit", function (val) {
  if (!val) {
    return 0;
  }
  val = parseFloat(parseFloat(val).toFixed(2));
  if (parseInt(val) > 100) {
    return 100;
  }
  else {
    return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ').replace(/[.]/g, ',');
  }
});

Vue.filter("firstLetterUppercase", function (val) {
  if (!val) {
    return 0;
  }
  return val.charAt(0).toUpperCase() + val.slice(1);
});

new Vue().$mount("#app");
