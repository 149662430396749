<template>
  <transition name="modal-fade">
    <div
      v-show="isShow"
      ref="modal"
      class="v-modal"
      :class="{ 'is-open': isShow }"
      v-body-scroll-lock:reserveScrollBarGap="isShow"
    >
      <div
        v-if="dialogVisible"
        class="v-modal__dialog"
        :class="{
        'v-modal__dialog--hidden': !dialogVisible,
        'v-modal__dialog--full': contentComponent.name === 'event-form',
        }"
        v-click-outside="closeModal"
        tabindex="0"
      >
        <input v-if="redirectUrl" ref="redirectUrl" type="hidden" :value="redirectUrl">
        <div class="v-modal__close" @click="closeModal">
          <inline-svg src="close-thin"></inline-svg>
        </div>
        <div v-if="title || contentComponent.title" class="v-modal__title" v-html="title || contentComponent.title"></div>
        <template v-if="isShow && !contentComponent.name">
          <slot />
        </template>
        <template v-else>
          <component :is="contentComponent.name" v-bind="contentComponent.props" />
        </template>
      </div>
      <div class="v-modal__spinner" v-else>
        <div class="v-modal__spinner-inner">
          <inline-svg src="spinner" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isShowed: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isShow: false,
      modal: undefined,
      redirectUrl: "",
      dialogVisible: true,
      contentComponent: {
        name: null,
        props: null,
        title: null,
      },
    };
  },
  mounted() {
    this.modal = this.$refs.modal;

    if (this.isShowed) {
      this.isShow = true;
    }

    document.addEventListener("keyup", e => {
      const key = e.code || e.key;
      if (key == "Escape") {
        this.closeModal();
      }
    });

    this.$root.$on('modal-cancel', () => {
      this.closeModal();
    });

    this.$root.$on('modal-link', (link) => {
      window.location.href = link;
    });

    this.$root.$on('modal-tab', (tab) => {
      this.$root.$emit('header-tabs-change', tab);
      this.isShow = false;
    });
  },
  methods: {
    openModal(link = '', content, awaitContent) {
      if (link) {
        this.redirectUrl = link;
      }
      if (content) {
        this.passContent(content);
      }
      if (awaitContent) {
        this.dialogVisible = false;
      }
      this.isShow = true;
    },
    passContent(componentData) {
      Object.assign(this.contentComponent, componentData);
      this.dialogVisible = true;
    },
    closeModal() {
      this.isShow = false;
    },
    toggleDialog(visible = true) {
      this.dialogVisible = visible;
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".v-modal";

#{$b} {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 80px;
  background: rgba(#102150, 0.9);
  opacity: 0;
  transition: opacity 0.15s linear;

  @include tablet {
    padding: 40px;
  }

  @include mobile {
    padding: 20px;
  }

  &.is-open {
    opacity: 1;
    display: flex;
    align-items: flex-start;
  }

  &__dialog {
    position: relative;
    box-sizing: border-box;
    margin: auto;
    padding: 34px 40px;
    border-radius: 0;
    width: 470px;
    max-width: calc(100% - 0.01px) !important;
    background: $white-true;
    transition: 0.3s linear;
    transition-property: opacity, transform;

    @include mobile {
      padding: 25px 30px;
      margin: 0 auto;
    }

    &--full {
      width: 1244px;
    }
  }

  &__spinner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    &-inner {
      flex: 50px 0 0;
      max-width: 50px;
    }
  }

  &__close {
    cursor: pointer;
    user-select: none;
    position: absolute;
    z-index: 10;
    top: 44px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin: 0;
    color: $color-black;
    text-decoration: none;
    transition: color 0.2s ease;

    @include mobile {
      top: 30px;
      right: 30px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-base;
    }

    .inline-svg {
      flex-shrink: 0;
      display: block;
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    color: $color-black;
    padding-right: 30px;
    margin: 0 0 28px;

    @include mobile {
      font-size: 26px;
    }

    @media screen and (max-width: 350px) {
      word-break: break-word;
    }
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
