<template>
  <form class="auth-form">
    <div class="auth-form__field">
      <text-input
        class="text-input--smaller"
        name="email"
        label="Логин"
        placeholder="E-mail"
        input-type="email"
        v-model="form.email"
        :error="$v.form.email.$error"
      />
      <div class="auth-form__error" v-if="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
      <div class="auth-form__error" v-if="$v.form.email.$error && !$v.form.email.email">Неправильный e-mail</div>
    </div>
    <div class="auth-form__field">
      <a href="#" class="auth-form__recovery-link" @click.prevent="changeForm('recovery')">
        Забыл пароль
      </a>
      <text-input
        class="text-input--smaller"
        name="password"
        label="Пароль"
        placeholder="Пароль"
        v-model="form.password"
        input-type="password"
        :error="$v.form.password.$error"
        isPassword
      />
      <div class="auth-form__error" v-if="$v.form.password.$error && !$v.form.password.required">Обязательное поле</div>
      <div class="auth-form__error" v-if="$v.form.password.$error && !$v.form.password.minLength">Должен содержать не менее 8 символов</div>
    </div>
    <div class="auth-form__bottom">
      <button
        class="button button--base"
        type="button"
        @click.prevent="submitForm"
      >
        Войти
      </button>
      <div class="auth-form__bottom-text">
        Ещё нет аккаунта?
        <a href="#" @click.prevent="changeForm('registration')">
          Зарегистрироваться
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import TextInput from "./TextInput";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "AuthForm",
  mixins: [validationMixin],
  components: { TextInput },
  props: {
    action: {
      type: String,
      default: ""
    },
    acceptPolicy: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
    }
  },
  data() {
    return {
      formSent: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      acceptPolicy: {}
    }
  },
  created() {
    this.bus.$on('submitLogin', () => {
      this.submitForm();
    });
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log('error');
      } else {
        const formData = new FormData();
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        formData.append('acceptPolicy', this.acceptPolicy ? 1 : 0);

        const options = {
          url: '/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=login',
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData
        };

        const { data } = await axios(options);

        if (
          typeof data.errors !== 'undefined'
          && typeof data.errors[0] !== 'undefined'
          && data.errors[0].customData === 'acceptPolicy'
        ) {
          this.$emit('showAcceptPolicyModal');
        }

        if (data.status === 'error') {
          console.log(data.errors);

        } else {
          this.reset();
          try {
            window.location.href = '/lk/';
          } catch (exception) {
            window.location.reload();
          }
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".auth-form";

#{$b} {
  &__bottom {
    display: block;
    text-align: center;
    color: $color-black;

    &:not(:first-child) {
      margin-top: 40px;

      @include mobile {
        margin-top: 30px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;

      &:not(:first-child) {
        margin-top: 12px;
      }

      a {
        color: $color-base;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);
    font-size: 12px;
    line-height: 160%;
    color: $color-error;
  }

  &__recovery-link {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    line-height: 1.6;
    color: $color-base;
    text-decoration: none;

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
