<template>
  <div
    class="event-picker"
    :class="{
      'event-picker--detail': isDetail,
      'is-collapse-opened': !isCollapsed,
      'is-calendar-showed': isCalendarShowed,
    }"
  >
    <div class="g-container">
      <div class="event-picker__inner">
        <div
          class="event-picker__event"
          :class="{ 'is-light': !image && !isDetail }"
          :style="{
            backgroundImage: image && !isDetail ? `url('${image}')` : ''
          }"
        >
          <div class="event-picker__event-content">
            <div
              v-if="isDetail"
              class="event-picker__event-date"
              :class="{ 'is-showed': isToday }"
            >
              <div class="event-picker__event-label">Сегодня</div>
            </div>
            <div class="event-picker__event-title" v-html="title"></div>
            <div class="event-picker__event-text" v-html="text"></div>
            <template v-if="isDetail">
              <div class="event-picker__actions">
                <transition name="fade-down">
                  <div class="event-picker__actions-inner" v-show="isCollapsed">
                    <a
                      v-if="hasDetail && !description"
                      class="button button--base"
                      :href="detailUrl"
                    >
                      Подробно
                    </a>
                    <div
                      v-if="description"
                      class="event-picker__collapse-button"
                      @click.prevent="toggleCollapse"
                    >
                      <span>Развернуть</span>
                      <inline-svg src="triangle-down" />
                    </div>
                  </div>
                </transition>
              </div>
            </template>
            <template v-else>
              <div class="event-picker__actions">
                <div class="event-picker__actions-inner">
                  <arrow-link :href="detailUrl" text-class="g-h4" direction="right" v-show="hasDetail">Подробно</arrow-link>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="event-picker__calendar">
          <calendar
            :is-detail="isDetail"
            :prop-day="selectedDay"
            :subtitle="monthTheme"
            :event-days="eventDays"
            is-wait-change
            @change-month="onChangeMonth"
            @setdate="onSetDate"
          />
        </div>
        <template v-if="isDetail">
          <div
            v-show="!isCalendarShowed"
            class="event-picker__toggle-calendar"
            @click.prevent="toggleCalendar"
          >
            <inline-svg src="menu-dots" />
            <span>Календарь</span>
          </div>
          <div
            v-show="isCalendarShowed"
            class="event-picker__close-calendar"
            @click.prevent="toggleCalendar"
          >
            <inline-svg src="close" />
          </div>
        </template>
      </div>
      <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div class="event-picker__desc" v-show="!isCollapsed">
          <div class="event-picker__desc-inner">
            <div class="event-picker__desc-content">
              <div class="event-picker__desc-title">{{ dateString }}</div>
              <div class="event-picker__desc-text" v-html="description"/>
              <div class="event-picker__actions">
                <div class="event-picker__actions-inner">
                  <div
                    class="event-picker__collapse-button is-open"
                    @click.prevent="toggleCollapse"
                  >
                    <span>Свернуть</span>
                    <inline-svg src="triangle-down" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="image" class="event-picker__desc-media">
              <div
                class="event-picker__desc-image"
                :style="`background-image: url('${image}')`"
              ></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Calendar from "./Calendar.vue";
import InlineSvg from './InlineSvg.vue';

const TODAY = new Date();
export default {
  name: "EventPicker",
  props: {
    url: {
      type: String,
      required: true
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    initData: {
      type: [Object, Array]
    }
  },
  components: {
    Calendar,
    InlineSvg
  },
  data() {
    return {
      items: [
        {
          date: "2021-03-25", // Год, месяц, число
          title: "14 марта",
          text:
            "Челябинские ученые разрабатывают технологию безотходной переработки медьсодержащих шлаков."
        }
      ],
      monthTheme: "",
      dateString: "",
      title: "",
      text: "",
      description: "",
      image: "",
      detailUrl: "",
      hasDetail: false,
      selectedDay: TODAY,
      isCollapsed: true,
      isCalendarShowed: false
    };
  },
  computed: {
    monthItems() {
      const data = {};
      this.items.map(item => {
        data[item.date] = item;
      });
      return data;
    },
    eventDays() {
      return this.items.map(item => {
        return item.date;
      });
    },
    isToday() {
      const today = moment(TODAY);
      return moment(this.selectedDay).isSame(today, 'day');
    }
  },
  async mounted() {
    if (this.isDetail && !Array.isArray(this.initData)) {
      this.dateString = this.initData.dateString;
      this.title = this.initData.title;
      this.text = this.initData.text;
      this.image = this.initData.image;
      this.detailUrl = this.initData.detailUrl;
      this.hasDetail = this.initData.hasDetail;
      this.description = this.initData.description;
    }
    await this.onChangeMonth(TODAY.getMonth() + 1, TODAY.getFullYear());
    this.onSetDate(TODAY);
    this.selectedDay = TODAY;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleCalendar() {
      this.isCalendarShowed = !this.isCalendarShowed;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    getFormatDate(date, format) {
      return date
        ? moment(date)
            .locale("ru")
            .format(format)
        : "";
    },
    async onChangeMonth(month, year) {
      const res = await axios.get(this.url + "&month=" + month + "&year=" + year);
      this.items = res.data.data.items;
      this.monthTheme = res.data.data.monthTheme;
      return res;
    },
    onSetDate(date) {
      this.selectedDay = date;
      const dateStr = moment(date).format("YYYY-MM-DD");
      const event = this.monthItems[dateStr];

      this.isCollapsed = true;

      if (event) {
        this.image = event.image;
        this.title = event.title;
        this.text = event.text;
        this.image = event.image;
        this.detailUrl = event.detailUrl;
        this.description = event.description;
        this.dateString = event.dateString;
        this.hasDetail = event.hasDetail;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".event-picker";

#{$b} {
  display: block;

  .g-container {
    @include low-desktop-only {
      padding-left: 0;
      padding-right: 0;
    }

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--detail {
    margin: 0 #{-$container-pd-right} 0 0;
    height: 100%;

    @include low-desktop {
      margin-right: #{-$container-pd-right-md};
    }

    @include tablet {
      margin-right: #{-$container-pd-right-tablet};
    }

    @include mobile {
      margin-right: #{-$container-pd-right-mobile};
    }

    .g-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__inner {
    display: flex;
    align-items: flex-start;

    @include tablet {
      flex-direction: column;
      align-items: stretch;
    }

    #{$b}--detail & {
      align-items: stretch;
      height: 100%;
      min-height: 408px;

      @include low-desktop {
        position: relative;
        margin-right: $container-pd-right-md;
      }

      @include tablet {
        margin-right: $container-pd-right-tablet;
      }

      @include mobile {
        min-height: 302px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: $container-pd-right-mobile;
      }
    }
  }

  &__event {
    width: 100%;
    position: relative;
    display: block;
    @include bg-cover;
    color: $white-true;
    background-image: url("../assets/img/event-default-bg.jpg");

    &.is-light {
      color: $color-black;
    }

    @include tablet {
      min-width: 100%;
    }

    #{$b}--detail & {
      background: transparent;

      @include low-desktop {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease;
      }
    }

    .is-calendar-showed & {
      opacity: 0;
      visibility: hidden;
    }

    &-content {
      min-height: 497px;
      display: flex;
      flex-direction: column;
      padding: 38px 26px 42px $offset-wrapper;

      @include desktop {
        min-height: 425px;
        padding: 38px 26px 42px $offset-wrapper-tablet;
      }

      @include low-desktop {
        padding: 40px 22px 40px $container-pd-left-md;
      }

      @include tablet {
        min-height: 425px;
        padding: 40px 40px 40px 60px;
      }

      @include mobile {
        min-height: 270px;
        background: linear-gradient(
          180deg,
          rgba(32, 32, 32, 0) 0%,
          #202020 92.59%
        );
        padding: 25px 30px;
      }

      .is-light & {
        @include mobile {
          background: linear-gradient(
            180deg,
            rgba(255,255,255, 0) 0%,
            rgba(255,255,255, 1) 92.59%
          );
        }
      }

      #{$b}--detail & {
        background: none;
        min-height: auto;
        padding: 0 30px 50px 0;

        @include mobile {
          padding: 0 0 40px 0;
        }
      }
    }

    &-date {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease;

      &.is-showed {
        opacity: 1;
        visibility: visible;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include tablet {
          margin-bottom: 25px;
        }

        @include mobile {
          margin-bottom: 15px;
        }
      }
    }

    &-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      border-radius: 30px;
      border: 1.5px solid currentColor;
      padding: 0 14px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      @include mobile {
        height: 24px;
        padding: 0 10px;
        border-width: 1px;
        font-size: 10px;
      }
    }

    &-title {
      min-height: 1em;
      position: relative;
      z-index: 0;
      font-weight: 700;
      font-size: 80px;
      line-height: 1;
      color: inherit;
      margin: 0 0 20px;

      @include desktop {
        font-size: 60px;
      }

      @include mobile {
        font-size: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        transform: translateY(-50%) skewX(-18deg);
        width: 30px;
        height: 244px;
        border: 1px solid $color-base;

        @include desktop {
          left: 28px;
          transform: translateY(-65%) skewX(-18deg);
          width: 18px;
          height: 154px;
        }

        @include mobile {
          left: 6px;
          transform: translateY(-50%) skewX(-18deg);
          width: 11px;
          height: 106px;
        }

        #{$b}--detail & {
          display: none;
        }
      }

      #{$b}--detail & {
        color: $white-true;
        font-size: 90px;
        line-height: 1;
        margin: 0 0 30px;

        @supports (-webkit-text-stroke: 1px $white-true) {
          -webkit-text-stroke: 1.5px $white-true;
          -webkit-text-fill-color: transparent;
        }

        @include desktop {
          margin-bottom: 25px;
        }

        @include mobile {
          font-size: 36px;
          margin-bottom: 10px;
        }
      }
    }

    &-text {
      margin-top: auto;
      font-size: 28px;
      line-height: 1.4;

      @include desktop {
        font-size: 22px;
      }

      @include tablet {
        max-width: 496px;
      }

      @include mobile {
        font-size: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      #{$b}--detail & {
        max-width: 100%;
        font-size: 40px;
        line-height: 1.3;

        @include tablet {
          font-size: 34px;
        }

        @include mobile {
          font-size: 16px;
          line-height: 1.6;
        }

        &:not(:last-child) {
          margin-bottom: 40px;

          @include desktop {
            margin-bottom: 35px;
          }

          @include tablet {
            margin-bottom: 25px;
          }

          @include mobile {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &__calendar {
    flex-shrink: 0;
    width: 586px;
    padding: 68px 60px 80px 107px;
    margin: -54px 0 -65px -35px;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);

    @include desktop {
      width: 500px;
    }

    @include low-desktop {
      padding: 55px 50px 55px 90px;
      margin: -40px 0 -40px -40px;
    }

    @include tablet {
      width: 100%;
      order: -1;
      margin: 0;
      padding: 30px 60px 60px;
    }

    @include mobile {
      display: none;
    }

    #{$b}--detail & {
      background: linear-gradient(180deg, rgba(16, 33, 80, 0) 0%, #102150 100%);
      margin: -120px 0 0;
      padding: 126px 60px 55px;

      @include low-desktop {
        margin: 0 0 0 auto;
        padding: 5px 0 0;
        width: 450px;
        position: absolute;
        top: 0;
        right: 100px;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease;
      }

      @include tablet {
        width: 468px;
        right: 92px;
      }

      @include mobile {
        display: none;
      }
    }

    .is-calendar-showed & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__desc {
    transition: height 0.3s ease-out;

    &-inner {
      display: flex;
      border-top: 1px solid $color-base;
      padding: 80px 0;

      @include low-desktop {
        padding: 60px 0;
      }

      @include tablet {
        padding: 50px $container-pd-right-tablet 60px 0;
      }

      @include mobile {
        padding: 32px $container-pd-right-mobile 40px 0;
      }
    }

    &-content {
      width: 100%;
      padding-right: 80px;

      @include low-desktop {
        padding-right: 30px;
      }

      @include tablet {
        padding-right: 0;
      }
    }

    &-media {
      flex-shrink: 0;
      width: 586px;

      @include desktop {
        width: 500px;
      }

      @include low-desktop {
        width: 300px;
      }

      @include tablet {
        display: none;
      }
    }

    &-title {
      display: block;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.5;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-text {
      &:not(:last-child) {
        margin-bottom: 46px;

        @include mobile {
          margin-bottom: 30px;
        }
      }

      p {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .content-panel {
        background: transparent;
        padding: 0;
        backdrop-filter: none;
        box-shadow: none;
        margin: 0;
      }

      .content-container {
        max-width: 100%;
      }
    }

    &-image {
      height: 100%;
      min-height: 496px;
      display: block;
      @include bg-cover;
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    overflow: hidden;
  }

  &__actions {
    #{$b}--detail & {
      min-height: 60px;
      overflow: hidden;

      @include mobile {
        min-height: 50px;
      }

      &-inner {
        display: flex;
        align-items: center;
        transition: 0.2s ease-in;
        transition-property: opacity, transform;

        &.fade-down-enter,
        &.fade-down-leave-to {
          opacity: 0;
          transform: translateY(100%);
        }

        &>* {
          &:not(:last-child) {
            margin-right: 40px;

            @include mobile {
              margin-right: 20px;
            }
          }
        }
      }

      .button {
        min-width: 266px;

        @include mobile {
          min-width: 190px;
        }
      }
    }
  }

  &__collapse-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include mobile {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid $color-base;
    }

    &>span {
      @include mobile {
        display: none;
      }
    }

    &.is-open {

      .inline-svg {
        transform: rotateX(-180deg);
      }
    }

    .inline-svg {
      width: 7px;
      height: 7px;
      transition: transform 0.2s ease;

      &:not(:first-child) {
        margin-left: 10px;

        @include mobile {
          margin: auto;
        }
      }
    }
  }

  &__toggle-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    cursor: pointer;
    height: 30px;
    padding: 0 13px 0 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-base;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1.5px solid $color-base;
    border-radius: 30px;

    @include low-desktop-min {
      display: none;
    }

    @include mobile {
      display: none;
    }

    .inline-svg {
      width: 18px;
      height: 8px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__close-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-base;
    border: 1.5px solid $color-base;
    border-radius: 30px;

    .inline-svg {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
