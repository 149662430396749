<template>
  <form class="pass-change-form">
    <template v-if="!formSent">
      <div class="pass-change-form__field">
        <text-input
          class="text-input--smaller"
          name="email"
          label="E-mail"
          placeholder="E-mail"
          v-model="form.email"
          is-read-only
          :error="$v.form.email.$error"
        />
        <div class="pass-change-form__error" v-if="$v.form.email.$error && !$v.form.email.required">Обязательное поле</div>
        <div class="pass-change-form__error" v-if="!$v.form.email.email">Некорректный e-mail</div>
      </div>
      <div v-if="isAuthorized" class="reg-form__field">
        <text-input
          class="text-input--smaller"
          name="password"
          label="Старый пароль"
          placeholder="Старый пароль"
          v-model="form.oldPassword"
          input-type="password"
          :error="$v.form.oldPassword.$error"
          isPassword
        />
        <div class="reg-form__error" v-if="$v.form.oldPassword.$error && !$v.form.oldPassword.required">Обязательное поле</div>
      </div>
      <div class="reg-form__field">
        <text-input
          class="text-input--smaller"
          name="password"
          label="Новый пароль"
          placeholder="Новый пароль"
          v-model="form.password"
          input-type="password"
          :error="$v.form.password.$error"
          isPassword
        />
        <div class="reg-form__error" v-if="$v.form.password.$error && !$v.form.password.required">Обязательное поле</div>
        <div class="reg-form__error" v-if="$v.form.password.$error && !$v.form.password.minLength">Должен содержать не менее 8 символов</div>
      </div>
      <div class="reg-form__field">
        <text-input
          class="text-input--smaller"
          name="confirm-password"
          label="Повторите новый пароль"
          placeholder="Повторите новый пароль"
          v-model="form.confirmPassword"
          input-type="password"
          :error="$v.form.confirmPassword.$error"
          isPassword
        />
        <div class="reg-form__error" v-if="$v.form.confirmPassword.$error && !$v.form.confirmPassword.required">Обязательное поле</div>
        <div class="reg-form__error" v-if="!$v.form.confirmPassword.sameAsPassword">Пароли не совпадают</div>
      </div>
      <div class="pass-change-form__bottom">
        <button
          class="button button--base"
          type="button"
          @click.prevent="submitForm"
        >
          Сменить пароль
        </button>
      </div>
    </template>
    <template v-else>
      <div class="pass-change-form__success">
        Вы успешно изменили пароль!
      </div>
    </template>
  </form>
</template>

<script>
import axios from "axios";
import TextInput from "./TextInput";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: "PassChangeForm",
  mixins: [validationMixin],
  components: { TextInput },
  props: {
    action: {
      type: String,
      default: ""
    },
    isAuthorized: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      required
    },
    checkword: {
      type: String,
      required
    }
  },
  data() {
    return {
      formSent: false,
      form: {
        email: this.email,
        checkword: this.checkword,
        oldPassword: "",
        password: "",
        confirmPassword: "",
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      checkword: {
        required
      },
      oldPassword: {
        required: requiredIf(function() {
          return this.isAuthorized;
        })
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('Форма неверно заполнена');
      } else {
        const response = await axios.post(
          '/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=changePassword',
          this.form
        );

        if (response.data.status === 'error') {
          console.log(response.data.errors);
          alert('Ошибка!');
        } else {
          this.formSent = true;
          this.reset();
          window.location.href = '/lk/';
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.checkword = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".pass-change-form";

#{$b} {
  &__bottom {
    display: block;
    text-align: center;
    color: $color-black;

    &:not(:first-child) {
      margin-top: 40px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;

      &:not(:first-child) {
        margin-top: 12px;
      }

      a {
        color: $color-base;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);
    font-size: 12px;
    line-height: 160%;
    color: $color-error;
  }

  &__success {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 1.6;

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
