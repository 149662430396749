<template>
  <div class="science-news">
    <div class="g-wrapper-offset-left">
      <div class="science-news-list">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="science-news-list__item"
          :class="{
            'science-news-list__item--col-1-3': isVertical(i),
            'science-news-list__item--no-image': !item.image
          }"
        >
          <component
            :is="item.hasDetail ? 'a' : 'div'"
            class="science-news-item"
            :class="{ 'science-news-item--vertical': isVertical(i) }"
            :href="item.hasDetail ? item.url : null"
          >
            <div
              v-if="item.image"
              class="science-news-item__image"
              :style="{ backgroundImage: `url('${item.image}')` }"
            />
            <div class="science-news-item__content">
              <div class="science-news-item__date">
                <div class="science-news-item__date-label">
                  {{ getCalendarDate(item.date) }}
                </div>
              </div>
              <div class="science-news-item__title" v-html="item.title"></div>
              <div v-if="item.hasDetail" class="science-news-item__more">
                <arrow-link tag="span" text-class="g-h4" direction="right">Подробнее</arrow-link>
              </div>
            </div>
          </component>
        </div>
      </div>
    </div>
    <div v-if="showLoadMore || paginator.NavPageNomer < paginator.NavPageCount" class="science-news__load-more">
      <div class="g-h4 g-offset-line g-offset-line--auto-tab">
        <span class="science-news__load-more-trigger" @click.prevent="loadMoreItems(false)">Показать ещё</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "ScienceNews",
  props: {
    url: {
      type: String,
      required: true
    },
    initialNewsItems: {
      type: Array,
      default: () => [
        {
          image: "/frontend/assets/img/content/science-news-01.jpg",
          date: "2021-03-31",
          title: "Ученые АлтГУ разработали пленку с антимикробными свойствами"
        },
        {
          image: "/frontend/assets/img/content/science-news-02.jpg",
          date: "2021-04-01",
          title: "Разработка набора для диагностики острых лейкозов"
        },
        {
          image: "/frontend/assets/img/content/science-news-03.jpg",
          date: "2021-04-05",
          title: "Международная ярмарка интеллектуальной литературы"
        },
        {
          image: "/frontend/assets/img/content/science-news-04.jpg",
          date: "2021-04-09",
          title: "Исследователи БФУ изучили, как размер селезенки влияет на тяжесть инфекционных заболеваний"
        },
        {
          image: "",
          date: "2021-05-18",
          title: "Ученые предложили способ неинвазивной диагностики рака мозга на основе анализа крови"
        },
        {
          image: "/frontend/assets/img/content/science-news-05.jpg",
          date: "2021-05-25",
          title: "Мегасайенс под водой: на Байкале запустили нейтринный телескоп"
        }
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    showLoadMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: this.initialNewsItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
    };
  },
  methods: {
    isVertical(index) {
      return index <= 2;
    },
    getCalendarDate(date) {
      moment.updateLocale("ru", {
        calendar: {
          lastDay: "[Вчера]",
          sameDay: "[Сегодня]",
          nextDay: "[Завтра]",
          lastWeek: "D MMMM",
          nextWeek: "D MMMM",
          sameElse: "D MMMM"
        }
      });
      return date ? moment(date).calendar() : "";
    },
    loadFirstPage() {
      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.items = [];
      }
      res.data.ITEMS.map(item => {
        this.items.push(item);
      });
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".science-news";

#{$b} {
  display: block;

  &-list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -40px;
    display: flex;
    flex-wrap: wrap;

    @include low-desktop {
      margin-left: -30px;
    }

    &__item {
      width: 100%;
      padding-left: 40px;
      margin-bottom: 40px;

      &--no-image {
        @include mobile {
          margin-top: -30px;
        }
      }

      @include low-desktop {
        padding-left: 30px;
      }

      @include mobile {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }

      &--col-1-3 {
        @include tablet-min {
          width: 33.3333%;
        }
      }
    }
  }

  &-item {
    min-height: 230px;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    z-index: 0;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);
    padding: 35px 40px 40px;
    color: $color-black;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @include low-desktop {
      padding: 25px 30px;
      min-height: 160px;
    }

    @include tablet {
      padding: 20px 30px;
    }

    @include mobile {
      padding: 30px;
    }

    &--vertical {

      #{$b}-item__content {
        @include tablet-min {
          max-width: 100%;
        }
      }

      #{$b}-item__image {
        @include tablet-min {
          width: 100%;
          height: auto;
          position: relative;
          top: 0;
          right: 0;
          left: 0;
          margin: -60px 0 24px 0;
          transition: top 0.2s ease-out;

          &:before {
            content: "";
            display: block;
            padding-top: 56%;
          }
        }

        @include desktop-min {
          margin: -80px 0 35px 0;
        }
      }

      #{$b}-item__date {
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &-label {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          border-radius: 30px;
          border: 1.5px solid $color-base;
          padding: 0 14px;
        }
      }
    }

    &__content {
      max-width: 620px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      @include low-desktop {
        max-width: 470px;
      }

      @include tablet {
        max-width: calc(100% - 290px);
      }

      @include mobile {
        max-width: 100%;
      }
    }

    &__image {
      width: calc((99.99% - 80px) / 3 - 80px);
      height: 190px;
      position: absolute;
      top: 0;
      right: 40px;
      display: block;
      @include bg-cover;

      @include low-desktop {
        width: calc((99.99% - 60px) / 3 - 60px);
        height: 124px;
        right: 30px;
      }

      @include tablet {
        width: 190px;
      }

      @include mobile {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: -60px 0 30px 0;
        transition: top 0.2s ease-out;

        &:before {
          content: "";
          display: block;
          padding-top: 56%;
        }
      }
    }

    &__date {
      display: block;
      line-height: 20px;

      &:not(:last-child) {
        margin-bottom: 25px;

        @include low-desktop {
          margin-bottom: 20px;
        }
      }

      &-label {
        display: inline-block;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    &__title {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.4;
      color: inherit;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @include desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
      }
    }

    &__more {
      margin-top: auto;
    }
  }

  a#{$b}-item {

    &--vertical {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 40px;
        left: 40px;
        height: 1.5px;
        width: auto;
        background-color: $color-blue-line;
        background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);
        transition: 0.2s ease-out;
        opacity: 0;

        @include desktop {
          right: 30px;
          left: 30px;
        }

        @include low-desktop {
          opacity: 1;
        }

        @include mobile {
          right: 20px;
          left: 20px;
        }
      }

      &:hover,
      &:focus {
        &::after {
          @include low-desktop-min {
            opacity: 1;
          }
        }

        #{$b}-item__image {
          @include low-desktop-min {
            top: -8px;
            transition-timing-function: ease-in;
          }
        }
      }
    }
  }

  &__load-more {

    &:not(:first-child) {
      margin-top: 30px;

      @include desktop {
        margin-top: 20px;
      }

      @include mobile {
        margin-top: 35px;
      }
    }

    &-trigger {
      cursor: pointer;
      color: $color-base;
      transition:  color .3s ease;

      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }
}
</style>
