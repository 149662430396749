<template>
  <div
    ref="wrapper"
    class="news-slider g-wrapper-offset-left"
    :class="{ _presidium: isPresidium }"
    :style="{marginRight: rightOffset}"
  >
    <swiper ref="newsSwiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="news-slider__controls-wrapper">
      <div class="news-slider__controls">
        <div class="news-slider__prev">
          <inline-svg src="arrow-left" />
        </div>
        <div class="news-slider__count swiper-pagination"></div>
        <div class="news-slider__next">
          <inline-svg src="arrow-right" />
        </div>
      </div>
      <div class="news-slider__bullets g-pagination-bullets"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsSlider",
  props: {
    isPresidium: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      rightOffset: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.newsSwiper.$swiper;
    },
    swiperOptions() {
      if (this.isPresidium) {
        return {
          slidesPerView: "auto",
          spaceBetween: 0,
          loop: this.count >= 6,
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          navigation: {
            prevEl: ".news-slider__prev",
            nextEl: ".news-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".news-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          breakpoints: {
            1024: {
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            }
          }
        }
      } else {
        return {
          slidesPerView: "auto",
          spaceBetween: 20,
          loop: true,
          watchOverflow: true,
          watchSlidesVisibility: true,
          slideVisibleClass: "swiper-slide-visible",
          navigation: {
            prevEl: ".news-slider__prev",
            nextEl: ".news-slider__next",
            disabledClass: "is-disabled"
          },
          pagination: {
            el: ".news-slider__bullets",
            type: "bullets",
            bulletClass: "g-pagination-bullet",
            bulletActiveClass: "is-active",
            clickable: true
          },
          breakpoints: {
            768: {
              slidesPerView: "auto",
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 40,
              pagination: {
                el: ".news-slider__count",
                type: "fraction"
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    if (this.isPresidium) {
      let offset = window.innerWidth - (this.$refs.wrapper.getBoundingClientRect().left + this.$refs.wrapper.offsetWidth) - 100;
      if (offset > 0) {
        this.rightOffset = -offset + 'px'
      }
    }

  },
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".news-slider";

#{$b} {
  color: $color-black;

  &._presidium {
    margin-left: -$offset-wrapper;
    @include desktop {
      margin-left: -$offset-wrapper-tablet;
    }
    @include tablet {
      margin-left: 0;
    }
  }

  &__inner {
    position: relative;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-out;
    height: auto;
    padding-top: 40px;

    @include desktop {
      padding-top: 30px;
    }

    @include tablet {
      width: 265px;
    }

    @include mobile {
      width: 214px;
    }

    &.swiper-slide-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__controls-wrapper {
    position: absolute;
    left: 0;
    bottom: 6px;
    z-index: 10;

    @include desktop {
      bottom: 4px;
    }

    @include tablet {
      position: static;
      margin: 30px 0 0;
    }
  }

  &__controls {
    height: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;

    @include desktop {
      width: 88px;
    }

    @include tablet {
      display: none;
    }

    .inline-svg {
      height: 100%;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {

    @include tablet-min {
      display: none;
    }
  }

  &-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    z-index: 0;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    box-shadow: 0px 40px 80px rgba($color: $black-true, $alpha: 0.05);
    padding: 40px 40px 30px;
    color: $color-black;

    @include desktop {
      padding: 30px;
    }

    @include mobile {
      padding: 20px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 40px;
      left: 40px;
      height: 1.5px;
      width: auto;
      background-color: $color-blue-line;
      background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);
      transition: 0.2s ease-out;
      opacity: 0;

      @include desktop {
        right: 30px;
        left: 30px;
      }

      @include low-desktop {
        opacity: 1;
      }

      @include mobile {
        right: 20px;
        left: 20px;
      }
    }

    &:hover,
    &:focus {
      &::after {
        opacity: 1;
      }

      #{$b}-item__image {
        top: -8px;
      }
    }

    &__image {
      position: relative;
      top: 0;
      display: block;
      @include bg-cover;
      margin: -80px 0 35px 0;
      transition: top .2s linear;

      @include desktop {
        margin: -60px 0 24px 0;
      }

      @include mobile {
        margin-top: -40px;
      }

      &:before {
        content: "";
        display: block;
        padding-top: 56%;
      }
    }

    &__content {
      margin-top: auto;
    }

    &__title {
      display: block;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-base;

      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }

    &__text {
      color: inherit;

      @include desktop {
        font-size: 14px;
        line-height: 1.5;
      }

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
</style>
