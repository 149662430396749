<template>
  <div class="search-page">
    <div class="g-wrapper-offset-left">
      <div class="search-page__top">
        <form ref="searchForm" class="search-page__search">
          <input class="search-page__search-input" type="text" placeholder="Поиск" name="q" v-model="search">
          <div class="search-page__search-button" @click="searchSubmit">
            <inline-svg src="search" />
          </div>
        </form>
      </div>

      <div class="search-page__result" v-if="items.length">
        <div class="search-page__result-side">
          <div class="search-page__result-number">
            {{ declOfNum(initFound || 0, ['Найден', 'Найдено', 'Найдено']) }}
            {{ initFound || 0 }}<br>
            {{ declOfNum(initFound || 0, ['результат', 'результата', 'результатов']) }}
          </div>
        </div>

        <div class="search-page__result-body">
          <ul class="search-page__list">
            <li v-for="item in items" :key="item.id">
              <div class="search-page__item">
                <div class="search-page__item-title" v-html="item.titleFormated"/>
                <div class="search-page__item-text" v-html="item.bodyFormated"/>
                <div class="search-page__item-source" v-if="item.url">
                  <a :href="item.url" class="search-page__item-source-link">{{ item.url }}</a>
                </div>
              </div>
            </li>
          </ul>

          <div class="search-page__load-more" v-show="needShowLoadMore">
            <div class="g-h4" @click="loadMore">Показать ещё</div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Ничего не найдено</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SearchPage",
  props: {
    initSearch: { type: String },
    initItems: { type: Array },
    initFound: { type: Number },
    initNavResult: { type: Object }
  },
  data() {
    return {
      search: this.initSearch,
      items: this.initItems,
      navResult: this.initNavResult
    };
  },
  computed: {
    needShowLoadMore() {
      return this.navResult.NavPageNomer < this.navResult.NavPageCount;
    },
    loadMoreUrl() {
      const page = parseInt(this.navResult.NavPageNomer) + 1;

      return '/search/?ajax=Y&q=' + this.search + '&PAGEN_1=' + page;
    }
  },
  methods: {
    searchSubmit() {
      this.$refs.searchForm.submit();
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    async loadMore() {
      try {
        const response = await axios({
          method: 'get',
          url: this.loadMoreUrl
        });
        if (response.data.status === 'error') {
          throw new Error(response.data.errors);
        }
        response.data.ITEMS.map(el => {
          this.items.push(el);
        });

        this.navResult = response.data.NAV_RESULT;

      } catch (e) {
        console.log(e);
        alert('Error!');
      }
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".search-page";
$offset: 60px;
$offset-mobile: 50px;

#{$b} {
  display: block;
  position: relative;

  &-section {
    position: relative;
    margin-top: #{-$offset};
    padding-bottom: 80px;

    @include low-desktop {
      padding-bottom: 50px;
    }

    @include mobile {
      margin-top: #{-$offset-mobile};
    }

    .figures {
      position: absolute;
      top: $offset;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include mobile {
        top: $offset-mobile;
      }
    }
  }

  &__search {
    display: block;
    position: relative;
    color: $white-true;

    &-input {
      height: $offset;
      width: 100%;
      display: block;
      padding: 0 20px 20px 0;
      border: 0;
      border-bottom: 6px solid $color-base;
      color: inherit;
      font-weight: 400;
      font-size: 22px;
      line-height: 40px;
      appearance: none;
      transition: .2s ease;
      transition-property: color, border-color;

      &:focus {
        border-bottom-color: rgba($color-base, 0.7);
      }

      @include mobile {
        height: $offset-mobile;
        font-size: 18px;
        line-height: 35px;
        padding: 0 20px 15px 0;
      }

      @include placeholder {
        color: inherit;
        opacity: 0.8;
      }
    }

    &-button {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: #{$offset - 6px};
      text-align: right;
      color: inherit;
      padding: 9px 0 0;
      transition: color .2s ease;

      &:hover,
      &:focus {
        color: $color-base;
      }

      @include mobile {
        height: #{$offset-mobile - 6px};
        padding-top: 5px;
      }

      .inline-svg {
        display: inline-block;
        width: 17px;
        height: 17px;
      }
    }
  }

  &__top {

    &:not(:last-child) {
      margin-bottom: 76px;

      @include tablet {
        margin-bottom: 56px;
      }
    }
  }

  &__result {
    display: flex;
    flex-wrap: wrap;

    &-body {
      width: calc(100% - 310px);

      @include low-desktop {
        width: calc(100% - 280px);
      }

      @include tablet {
        width: calc(100% - 236px);
      }

      @include mobile {
        width: 100%;
      }
    }

    &-side {
      width: 310px;
      padding-left: 50px;
      order: 99;
      text-align: right;

      @include low-desktop {
        width: 280px;
      }

      @include tablet {
        width: 236px;
      }

      @include mobile {
        order: 0;
        text-align: left;
        padding-left: 0;
        padding-bottom: 32px;
        border-bottom: 1.5px solid rgba($color-black, 0.15);
        margin-bottom: 32px;
        width: 100%;
      }
    }

    &-number {
      display: block;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-black;
      opacity: 0.5;

      @include mobile {
        font-size: 10px;
        line-height: 18px;
      }
    }

    b {
      font-weight: normal;
      background-color: #C4DAFF;
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
      padding-bottom: 40px;
      border-bottom: 1.5px solid rgba($color-black, 0.15);

      @include low-desktop {
        padding-bottom: 36px;
      }

      @include mobile {
        padding-bottom: 30px;
      }

      &:not(:first-child) {
        padding-top: 40px;

        @include low-desktop {
          padding-top: 36px;
        }

        @include mobile {
          padding-top: 30px;
        }
      }
    }
  }

  &__item {
    display: block;
    color: $color-black;

    &-title {
      display: block;
      text-decoration: none;
      color: inherit;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.4;

      @include low-desktop {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 1.5;
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include mobile {
          font-size: 16px;
        }
      }
    }

    &-text {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;
      color: inherit;

      @include mobile {
        font-size: 12px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;

        @include mobile {
          margin-bottom: 16px;
        }
      }
    }

    &-source {

      &>* {
        &:not(:last-child) {
          margin-right: 30px;

          @include mobile {
            margin-right: 20px;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 20px;

        @include mobile {
          margin-top: 16px;
        }
      }
    }

    &-source-link {
      display: inline-block;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;
      color: rgba($black-true, 0.5);
      transition: color .2s ease;

      @include mobile {
        font-size: 12px;
      }

      &:hover {
        color: $color-base;
      }
    }
  }

  &__load-more {

    &:not(:first-child) {
      margin-top: 80px;

      @include low-desktop {
        margin-top: 60px;
      }

      @include tablet {
        margin-top: 55px;
      }
    }

    .g-h4 {
      cursor: pointer;
      display: inline-block;
      position: relative;
      color: $color-base;
      transition:  color .3s ease;

      @include tablet {
        display: flex;
        align-items: center;
      }

      &:hover,
      &:focus {
        color: $color-black;
      }

      &:after {
        content: "";
        width: 116px;
        height: 1.5px;
        position: absolute;
        top: calc(0.5em + 2px);
        right: calc(100% + 39px);
        background-color: $color-blue-line;
        background: linear-gradient(0deg, $color-blue-line 0%, #4f92ff 100%);

        @include desktop {
          width: 85px;
          right: calc(100% + 31px);
        }

        @include tablet {
          position: static;
          margin-left: auto;
        }

        @include mobile {
          width: 55px;
        }
      }
    }
  }
}
</style>
