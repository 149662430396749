<template>
  <div class="gallery-slider">
    <div class="gallery-slider__inner g-wrapper-offset-left">
      <swiper ref="gallerySwiper" :options="swiperOptions">
        <swiper-slide v-for="(slide, i) in initialItems" :key="i">
          <div class="gallery-slider__item">
            <div v-if="slide.type === 'image'" class="gallery-slider__image">
              <img :src="slide.file.src" :alt="slide.file.alt">
            </div>
            <div v-else-if="slide.type === 'video'" class="gallery-slider__video">
              <div class="gallery-slider__video-play" v-video-controls>
                <inline-svg src="play-btn-2"></inline-svg>
              </div>
              <object-fit-video :poster="slide.file.poster">
                <source :src="slide.file.src">
              </object-fit-video>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="gallery-slider__controls-wrapper">
        <div class="gallery-slider__controls">
          <div class="gallery-slider__prev">
            <inline-svg src="arrow-left" />
          </div>
          <div class="gallery-slider__count swiper-pagination"></div>
          <div class="gallery-slider__next">
            <inline-svg src="arrow-right" />
          </div>
        </div>
        <div class="gallery-slider__bullets g-pagination-bullets"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "GallerySlider",
  props: {
    initialItems: {
      type: Array,
      default: () => [
        {
          type: "image",
          file: {
            src: "/frontend/assets/img/events/events-gallery-01.jpg",
            alt: "Картинка"
          }
        },
        {
          type: "video",
          file: {
            poster: "/frontend/assets/img/events/events-gallery-03.jpg",
            src: "https://cdn.videvo.net/videvo_files/video/free/2014-02/small_watermarked/Microscope_5__Scientist__Videvo_preview.webm",
          }
        },
        {
          type: "image",
          file: {
            src: "/frontend/assets/img/events/events-gallery-02.jpg",
            alt: "Картинка"
          }
        },
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.gallerySwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: this.initialItems.length > 2,
        watchSlidesVisibility: true,
        slideVisibleClass: "swiper-slide-visible",
        navigation: {
          prevEl: ".gallery-slider__prev",
          nextEl: ".gallery-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".gallery-slider__bullets",
          type: "bullets",
          bulletClass: "g-pagination-bullet",
          bulletActiveClass: "is-active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
            pagination: {
              el: ".gallery-slider__count",
              type: "fraction"
            }
          },
          1440: {
            slidesPerView: 2,
            spaceBetween: 40,
            pagination: {
              el: ".gallery-slider__count",
              type: "fraction"
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".gallery-slider";

#{$b} {
  display: block;

  &__inner {
    position: relative;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    height: auto;

    &.swiper-slide-active {
      opacity: 1;
      visibility: visible;

      & ~ .swiper-slide {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__controls-wrapper {
    position: absolute;
    left: 0;
    bottom: 6px;
    z-index: 10;

    @include desktop {
      bottom: 4px;
    }

    @include tablet {
      position: static;
      margin: 40px 0 0;
    }
  }

  &__controls {
    height: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: inherit;

    @include desktop {
      width: 88px;
    }

    @include tablet {
      display: none;
    }

    .inline-svg {
      height: 100%;
    }

    .swiper-pagination {
      position: static;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__prev,
  &__next {
    outline: 0;
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    flex-shrink: 0;
    width: 20.5px;
    height: 11px;
    transition: 0.3s;
    color: inherit;

    @include desktop {
      width: 15.5px;
      height: 11px;
    }

    &:hover {
      &:not(.is-disabled) {
        color: $color-base;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: 0.35;
    }

    &.swiper-button-lock {
      display: none;
    }
  }

  &__bullets {

    @include tablet-min {
      display: none;
    }
  }

  &__item {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  &__image {
    display: block;
    position: relative;
    padding-top: 65%;

    &>img {
      @include full-abs;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__video {
    display: block;
    position: relative;
    padding-top: 65%;

    &-play {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-color: $white-true;
      color: $color-base;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      @include mobile {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

      &._played {
        opacity: 0;
        visibility: hidden;
      }

      .inline-svg {
        flex-shrink: 0;
        width: 11px;
        height: 15px;
        margin-left: 4px;

        @include mobile {
          width: 9.17px;
          height: 12.5px;
          margin-left: 2px;
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
      outline: 0;
      object-fit: cover;
      @include full-abs;
    }
  }
}
</style>
