<template>
  <div v-if="closeModal" class="modal-cookie">
    <div class="g-container">
      <div class="modal-cookie__body">
        <div class="modal-cookie__text">
          <slot name="text" />
        </div>
        <div class="modal-cookie__btn" @click.prevent="closeCookie()">
          <slot name="button" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ModalCookie",
    data() {
      return {
        closeModal: true
      };
    },
    mounted() {
      this.closeModal = !(localStorage.getItem("cookie-modal") === "true");
    },
    methods: {
      closeCookie() {
        this.closeModal = !this.closeModal;
        localStorage.setItem("cookie-modal", "true");
      }
    }
  };
</script>

<style lang="scss">
  @import "../scss/base/includes.scss";
  $b: ".modal-cookie";

  #{$b} {
    position: fixed;
    bottom: 35px;
    z-index: 999;
    width: 100%;

    @include tablet-only {
      bottom: 40px;
    }

    @include mobile {
      bottom: 21px;
    }

    &__body {
      display: flex;
      position: relative;
      background-color: white;
      padding: 26px 31px 19px;
      box-shadow: 0 10px 30px 8px rgba(0, 0, 0, 0.15);

      @include tablet {
        flex-wrap: wrap;
      }

      @include mobile {
        padding: 26px 20px 20px 18px;
      }
    }

    &__text {
      font-family: "Montserrat", Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      width: calc(100% - 150px);
      margin-right: 25px;

      @include tablet {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }

      @include mobile {
        font-size: 15px;
        line-height: 24px;
      }

      &-link {
        text-decoration: underline;
      }
    }

    &__btn {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 11px;

      .leaf-modal-close {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
