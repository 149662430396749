<template>
  <form class="reg-form">
    <div class="reg-form__field">
      <text-input
        class="text-input--smaller"
        name="email"
        label="Логин"
        placeholder="E-mail"
        v-model="form.email"
        :error="$v.form.email.$error"
      />
      <div
        class="reg-form__error"
        v-if="$v.form.email.$error && !$v.form.email.required"
      >
        Обязательное поле
      </div>
      <div class="reg-form__error" v-if="!$v.form.email.email">
        Неправильный e-mail
      </div>
    </div>
    <div class="reg-form__field">
      <text-input
        class="text-input--smaller"
        name="password"
        label="Пароль"
        placeholder="Пароль"
        v-model="form.password"
        input-type="password"
        :error="$v.form.password.$error"
        isPassword
      />
      <div
        class="reg-form__error"
        v-if="$v.form.password.$error && !$v.form.password.required"
      >
        Обязательное поле
      </div>
      <div
        class="reg-form__error"
        v-if="$v.form.password.$error && !$v.form.password.minLength"
      >
        Должен содержать не менее 8 символов
      </div>
    </div>
    <div class="reg-form__field">
      <text-input
        class="text-input--smaller"
        name="confirm-password"
        label="Повторите пароль"
        placeholder="Повторите пароль"
        v-model="form.confirmPassword"
        input-type="password"
        :error="$v.form.confirmPassword.$error"
        isPassword
      />
      <div
        class="reg-form__error"
        v-if="
          $v.form.confirmPassword.$error && !$v.form.confirmPassword.required
        "
      >
        Обязательное поле
      </div>
      <div
        class="reg-form__error"
        v-if="!$v.form.confirmPassword.sameAsPassword"
      >
        Пароли не совпадают
      </div>
    </div>
    <div class="reg-form__field">
      <div class="reg-form__checkbox">
        <input
          v-model="agreement"
          name="agreement"
          id="agreement"
          type="checkbox"
        />
        <label for="agreement">
          <span>
            Принимаю условия
            <a
              href="/upload/documents/Пользовательское_соглашение_годнауки.pdf"
              target="_blank"
              >Пользовательского соглашения</a
            >
            и&nbsp;<a
              href="/upload/documents/Политика_конфиденциальности_годнауки.pdf"
              target="_blank"
              >Политики конфиденциальности</a
            >
            по&nbsp;работе с&nbsp;персональными данными
          </span>

          <div class="reg-form__error" v-if="$v.agreement.$error">
            Необходимо согласиться с условиями
          </div>
        </label>
      </div>
    </div>
    <div class="reg-form__bottom">
      <button
        class="button button--base"
        type="button"
        @click.prevent="submitForm"
      >
        Зарегистрироваться
      </button>
      <div class="reg-form__bottom-text">
        Есть аккаунт?
        <a href="#" @click.prevent="changeForm('authorization')">
          Войти
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import TextInput from "./TextInput";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "RegForm",
  mixins: [validationMixin],
  components: { TextInput },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      passwordText: "",
      formSent: false,
      form: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      agreement: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
        // goodPassword(password) {
        //   let result = true;
        //   this.passwordText = "";
        //
        //   // Проверка длины пароля
        //   if (password.length < 8) {
        //     this.passwordText += "Должен содержать не менее 8 символов";
        //     result = false;
        //   }
        //
        //   return result;
        // }
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    },
    agreement: {
      required,
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    async submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        console.log("error");
      } else {
        const formData = new FormData();
        formData.append("email", this.form.email);
        formData.append("password", this.form.password);
        formData.append("confirmPassword", this.form.confirmPassword);

        const options = {
          url:
            "/bitrix/services/main/ajax.php?mode=class&c=dalee:auth&action=register",
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: formData
        };
        const response = await axios(options);

        this.formSent = true;

        if (response.data.status === "error") {
          console.error(response.data.errors);
          alert("Ошибка!");
        } else {
          this.reset();
          window.location.href = "/lk/";
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.form.email = "";
      this.form.password = "";
      this.form.confirmPassword = "";
    },
    changeForm(name) {
      this.$emit("onChangeForm", name);
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".reg-form";

#{$b} {
  &__bottom {
    display: block;
    text-align: center;
    color: $color-black;

    &:not(:first-child) {
      margin-top: 40px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;

      &:not(:first-child) {
        margin-top: 12px;
      }

      a {
        color: $color-base;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .button {
      display: flex;
      width: 100%;
    }
  }

  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__error {
    display: block;
    font-size: 12px;
    line-height: 160%;
    color: $color-error;
    margin: 3px 0 0;
  }

  &__checkbox {
    position: relative;

    input {
      display: none;

      &:checked {
        + label {
          &:after {
            opacity: 1;
            visibility: visible;
            transform: none;
          }
        }
      }

      &:not(:checked) {
        + label {
          &:hover,
          &:focus {
            &:before {
              border-color: $color-base;
            }
          }
        }
      }
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: 35px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;

      @include mobile {
        font-size: 12px;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
      }

      &:before {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: $line-width solid $color-border;
        transition: border-color 0.2s ease;
      }

      &:after {
        top: 0;
        left: 5px;
        width: 19px;
        height: 15px;
        background-image: $img-check;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-5px);
        transition: 0.2s;
      }

      a {
        color: $color-base;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
