<template>
  <div class="tabs">
    <div class="tabs-container">
      <div v-dragscroll.x:nochilddrag class="tabs-overflow">
        <ul class="tabs-list g-h4 g-offset-line g-offset-line--no-tab">
          <li
            v-for="(item, i) in tabs"
            :id="item.id"
            :key="i"
            :class="{ 'is-active': item.isActive }"
          >
            <a v-if="item.url" class="tabs-list__item" :href="item.url">
              <span v-text-reveal>{{ item.title }}</span>
            </a>
            <span v-else class="tabs-list__item" @click.prevent="selectTab(i)">
              <span v-text-reveal>{{ item.title }}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="tabs-content g-wrapper-offset-left">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return {
      tabs: [],
    };
  },
  mounted() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab, i) => {
        this.tabs[i].isActive = false;
      });
      this.tabs[selectedTab].isActive = true;
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".tabs";

#{$b} {

  &-overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-left-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-left-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-left-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-left-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &-list {
    min-width: 100%;
    display: inline-flex;
    align-items: center;
    margin-bottom: 26px;

    @include low-desktop {
      margin-bottom: 22px;
    }

    &>* {
      &:not(:last-child) {
        margin-right: 40px;

        @include low-desktop {
          margin-right: 24px;
        }
      }
    }

    &__item {
      cursor: pointer;
      display: block;
      text-decoration: none;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $color-black;
      white-space: nowrap;
      transition: .2s ease;
      transition-property: color;

      &:hover {
        color: $color-base;
      }

      .is-active & {
        color: $color-base;
      }
    }
  }
}
</style>
