<template>
  <div
    class="burger-menu-button"
    :class="{ 'burger-menu-button--showed': isShowed }"
    @click.prevent="toggleMenu"
    >
    <template v-if="breakpoint.isTablet">
      <span class="burger-menu-button__text">Меню</span>
    </template>
    <template v-if="!breakpoint.isDesktop">
      <span class="burger-menu-button__text">Карта сайта</span>
    </template>
    <div class="burger-menu-button__icon">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BurgerMenuButton",
  data() {
    return {
      breakpoint: this.getBreakpoint(),
      isShowed: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateBreakpoint);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateBreakpoint);
    }
  },
  methods: {
    getBreakpoint() {
      const { innerWidth } = window;
      return {
        isMobile: innerWidth < 768,
        isTablet: innerWidth < 1024,
        isDesktop: innerWidth < 1280
      };
    },
    updateBreakpoint() {
      const { isMobile, isTablet, isDesktop } = this.getBreakpoint();
      this.breakpoint.isMobile = isMobile;
      this.breakpoint.isTablet = isTablet;
      this.breakpoint.isDesktop = isDesktop;
    },
    toggleMenu() {
      this.isShowed = !this.isShowed;
      this.$root.$emit("burgerMenuToggle", this.isShowed);
    },
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes";

$b: ".burger-menu-button";

#{$b} {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: inherit;
  user-select: none;

  #{$b}--showed & {
    z-index: 110;
  }

  @include tablet {
    text-transform: none;
    letter-spacing: 0;
  }

  @include mobile {
    width: 20px;
    height: 20px;
  }

  @include hover {
    #{$b}__text {
      color: $color-base;
    }

    #{$b}__icon {
      span {
        background-color: $color-base;
      }
    }
  }

  &__text {
    position: relative;
    transition: .3s ease-in-out;
    @include mobile {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 15px;

      @include tablet {
        margin-right: 10px;
      }
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: .3s ease-in-out;

    span {
      display: block;
      position: absolute;
      left: 0;
      height: 1px;
      width: 100%;
      background: currentColor;
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 1px;

        #{$b}--showed & {
          top: 7px;
          width: 0%;
          left: 50%;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 7px;
      }

      &:nth-child(2) {
        #{$b}--showed & {
          transform: rotate(45deg);
        }
      }

      &:nth-child(3) {
        #{$b}--showed & {
          transform: rotate(-45deg);
        }
      }

      &:nth-child(4) {
        top: 13px;

        #{$b}--showed & {
          top: 7px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}
</style>
